import React, { useState, useEffect, useContext } from "react";
import "./ImportFinancingPlansFolderModal.css";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import AppContext from "../../../../../context/AppContext";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import { addFinancingPlanToFolderApi } from "../../../../../api/FolderApi";
import { useForm } from "react-hook-form";
import Searchbar from "../../../../molecules/searchbar/Searchbar";
import { fetchAllFinancingPlanApi } from "../../../../../api/FinancingPlanApi";
import Shop from "../../../../atoms/icons/header/shop/Shop";
import Building from "../../../../atoms/icons/header/building/Building";
import LinkIcon from "../../../../atoms/icons/account/linkIcon/LinkIcon";
import { getShortedString } from "../../../../../utils/Utils";

function ImportFinancingPlansFolderModal({ folderId, getUuid, onFinancingPlanAdded = () => {}, folder }) {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [financingPlans, setFinancingPlans] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedFinancingPlans, setSelectedFinancingPlans] = useState([]);
  const { setModalVisible, createNotification } = useContext(AppContext);

  useEffect(() => {
    async function fetchFinancingPlans() {
      const res = (await fetchAllFinancingPlanApi(getUuid())).data;

      const fdcPlans = res["financing_plan_fdc"]
        .map(plan => ({ ...plan, financing_plan_type: "fdc" }))
        .filter(plan => !plan.archived);
      const mursPlans = res["financing_plan_murs"]
        .map(plan => ({ ...plan, financing_plan_type: "murs" }))
        .filter(plan => !plan.archived);

      const allFinancingPlans = [...fdcPlans, ...mursPlans];

      setFinancingPlans(allFinancingPlans);
    }
    fetchFinancingPlans();
  }, [getUuid]);

  const filteredFinancingPlans = financingPlans.filter(
    plan => plan.financing_plan_name && plan.financing_plan_name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  function toggleFinancingPlanSelection(financingPlanId) {
    if (isFinancingPlanInFolder(financingPlanId)) {
      return;
    }
    if (selectedFinancingPlans.includes(financingPlanId)) {
      setSelectedFinancingPlans(selectedFinancingPlans.filter(id => id !== financingPlanId));
    } else {
      setSelectedFinancingPlans([...selectedFinancingPlans, financingPlanId]);
    }
  }

  function isFinancingPlanInFolder(financingPlanId) {
    return folder.financing_plans.some(plan => plan.financing_plan_id === financingPlanId);
  }

  async function handleImportFinancingPlans() {
    try {
      const financingPlansToAdd = selectedFinancingPlans.filter(
        financingPlanId => !isFinancingPlanInFolder(financingPlanId),
      );

      if (financingPlansToAdd.length === 0) return setModalVisible(false);

      await Promise.all(
        financingPlansToAdd.map(financingPlanId => addFinancingPlanToFolderApi(folderId, financingPlanId)),
      );

      onFinancingPlanAdded();
      createNotification(<>Les plans de financement ont été ajoutés avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'ajout des plans de financement</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(handleImportFinancingPlans)} className='create-deliverable-modal-list'>
      <div className='import-financingplan-checkbox-header'>
        <h3>Liste des plans de financement (terminés)</h3>
        <p>Sélectionnez les plans de financement à importer dans votre dossier.</p>
      </div>
      <Searchbar
        onChange={value => setSearchValue(value)}
        placeholder='Rechercher un plan de financement...'
        bgColor='var(--white)'
        className='import-financingplan-searchbar'
      />
      <div className='items-table-body financing-plans-table mt-md import-estimations-list-container'>
        {filteredFinancingPlans.length ? (
          filteredFinancingPlans.map(plan => {
            const isInFolder = isFinancingPlanInFolder(plan.financing_plan_id);
            return (
              <div
                className='item-overview-container'
                key={plan.financing_plan_id}
                onClick={() => toggleFinancingPlanSelection(plan.financing_plan_id)}>
                <Checkbox
                  name={`financingPlan-${plan.financing_plan_id}`}
                  className='create-deliverable-checkbox'
                  useForm={{ setValue }}
                  errors={errors}
                  checked={selectedFinancingPlans.includes(plan.financing_plan_id)}
                  disabled={isInFolder}
                />
                <div>{getShortedString(plan.financing_plan_name, 30)}</div>
                <div className={`estimation-type-${plan.financing_plan_type === "fdc" ? "fonds" : "murs"}`}>
                  {plan.financing_plan_type === "fdc" ? (
                    <Shop width='16px' color='var(--white)' className='mr-sm' />
                  ) : (
                    <Building width='16px' color='var(--white)' className='mr-sm' />
                  )}
                  {plan.financing_plan_type === "fdc" ? "Fonds" : "Murs"}
                </div>
                <div />
                <div>{plan?.date_creation?.split(" ")[0]}</div>
                {plan.estimation_data ? (
                  <div className='financing-plan-link-icon-container'>
                    <div className='financing-plan-link-icon'>
                      <LinkIcon width='11px' />
                    </div>
                    <div className='financing-plan-link-tooltip'>
                      Ce plan de financement est lié à l'estimation {plan.estimation_data.estimation_name}
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            );
          })
        ) : (
          <div className='import-financingplan-no-financingplan'>
            <p>Aucun plan de financement trouvé</p>
          </div>
        )}
      </div>
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Importer
        </FilledButton>
      </div>
    </form>
  );
}

export default ImportFinancingPlansFolderModal;
