import React from "react";

function ProgressCircle({
  currentStep = 0,
  totalSteps = 1,
  radius = 10,
  strokeWidth = 4,
  className = "",
  color = "var(--gold)",
  bgColor = "var(--white)",
}) {
  if (currentStep === NaN) currentStep = 1;

  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = 2 * Math.PI * normalizedRadius;
  const progress = currentStep / totalSteps;
  const strokeDashoffset = circumference - progress * circumference;

  return (
    <div className={className}>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          cx={radius}
          cy={radius}
          r={normalizedRadius}
          fill='transparent'
          stroke={bgColor}
          strokeWidth={strokeWidth}
        />
        <circle
          cx={radius}
          cy={radius}
          r={normalizedRadius}
          fill='transparent'
          stroke={color}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset || 1}
          strokeLinecap='round'
          transform={`rotate(-90 ${radius} ${radius})`}
          style={{
            transition: "stroke-dashoffset 0.5s ease-in-out",
          }}
        />
      </svg>
    </div>
  );
}

export default ProgressCircle;
