import "./AccountFolders.css";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import Searchbar from "../../../../../components/molecules/searchbar/Searchbar";
import ComponentsRow from "../../../../../components/organisms/componentsRow/ComponentsRow";
import Add from "../../../../../components/atoms/icons/general/add/Add";
import React, { useContext, useEffect, useState } from "react";
import Download from "../../../../../components/atoms/icons/general/download/Download";
import Pen from "../../../../../components/atoms/icons/account/pen/Pen";
import Archive from "../../../../../components/atoms/icons/account/archive/Archive";
import Unarchive from "../../../../../components/atoms/icons/account/unarchive/Unarchive";
import Duplicate from "../../../../../components/atoms/icons/account/duplicate/Duplicate";
import { useWindowSize } from "@uidotdev/usehooks";
import AppContext from "../../../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { archiveFolderApi, fetchUserFoldersApi } from "../../../../../api/FolderApi";
import DuplicateFolderModal from "../../../../../components/forms/modals/account/duplicateFolderModal/DuplicateFolderModal";
import { useAuth } from "../../../../../context/AuthContext";
import CreateFolderModal from "../../../../../components/forms/modals/account/createFolderModal/CreateFolderModal";
import FolderDetail from "./FolderDetail";
import Tuto from "../../../../../components/atoms/icons/general/tuto/Tuto";
import Bracket from "../../../../../components/atoms/icons/general/bracket/Bracket";
import ReviewButton from "../../../../../components/molecules/buttons/reviewButton/ReviewButton";
import Copy from "../../../../../components/atoms/icons/account/copy/Copy";
import { copyToClipboard } from "../../../../../utils/Utils";
import { Link } from "react-router-dom";

function AccountFolders() {
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [folders, setFolders] = useState([]);
  const [visibleFolders, setVisibleFolders] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(null); // Nouvel état pour la vue de détail
  const [currentFolder, setCurrentFolder] = useState(null); // Dossier sélectionné pour la vue de détail
  const [resetSearchValueTrigger, setResetSearchValueTrigger] = useState(false);
  const [openedFolderDropdown, setOpenedFolderDropdown] = useState(-1);
  const { createNotification, setModalContent, setModalVisible, setAppLoaderVisible, showTooltip } =
    useContext(AppContext);
  const { getUuid } = useAuth();
  const navigate = useNavigate();
  const { width } = useWindowSize();

  useEffect(() => {
    setAppLoaderVisible(true);
    fetchFolders();
  }, []);

  useEffect(() => {
    setResetSearchValueTrigger(!resetSearchValueTrigger);
  }, [activeTab]);

  useEffect(() => {
    if (folders.length) setVisibleFolders(getFilteredFolders(folders));
  }, [activeTab, searchValue]);

  async function fetchFolders(folderIdToOpenAfterFetch = null) {
    try {
      let res = (await fetchUserFoldersApi(getUuid())).data;

      const foldersWithEstimations = res.map(folderItem => {
        const { folder } = folderItem;

        const estimations = [
          ...folder.estimations.fonds_de_commerce,
          ...folder.estimations.murs_commerciaux,
          ...folder.estimations.titres_de_societe,
        ];

        const financing_plans = [
          ...(folder.financing_plans.financing_plan_fdc || []).map(planWrapper => ({
            ...planWrapper.financing_plan,
            financing_plan_type: "fdc",
          })),
          ...(folder.financing_plans.financing_plan_murs || []).map(planWrapper => ({
            ...planWrapper.financing_plan,
            financing_plan_type: "murs",
          })),
        ];

        const credit_simulators = [
          ...(folder.credit_simulators.map(creditSimulator => creditSimulator.credit_simulator) || []),
        ];

        return {
          ...folder.data,
          estimations: estimations,
          financing_plans: financing_plans,
          credit_simulators: credit_simulators,
        };
      });

      foldersWithEstimations.sort((a, b) => stringToDate(b.date_creation) - stringToDate(a.date_creation));

      setFolders(foldersWithEstimations);
      setVisibleFolders(getFilteredFolders(foldersWithEstimations));

      setAppLoaderVisible(false);

      if (folderIdToOpenAfterFetch) navigate(`/mon-compte/mes-dossiers/${folderIdToOpenAfterFetch}`);
    } catch (error) {
      if (error.response) {
        navigate(`/${error.response.status}`);
      } else {
        createNotification(
          <>Une erreur est survenue lors du chargement des dossiers. Veuillez réessayer plus tard.</>,
          "var(--red)",
          "var(--dark-blue)",
        );
        setAppLoaderVisible(false);
      }
    }
  }

  async function archiveFolder(folderId, archive) {
    try {
      await archiveFolderApi(folderId, archive);
      await fetchFolders();
      createNotification(<>Votre dossier a été {archive ? "archivé" : "désarchivé"} avec succès</>);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors {archive ? "de l'archivage" : "du désarchivage"} de votre dossier. Veuillez
          réessayer
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function stringToDate(dateString) {
    const parts = dateString.split(/[\s/:]+/);
    return new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4], parts[5]);
  }

  function copyIdToClipboard(e, id) {
    e.preventDefault();
    e.stopPropagation();

    copyToClipboard(id, <>La référence a été copiée dans votre presse-papier avec succès.</>, createNotification);
  }

  function openFolderDetail(folderId) {
    const folder = folders.find(f => f.folder_id === folderId);

    if (folder) {
      setCurrentFolderId(folderId);
      setCurrentFolder(folder);
    } else {
      createNotification(
        <>Le dossier n'a pas pu être trouvé. Veuillez réessayer plus tard.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function getFilteredFolders(folders) {
    if (searchValue === "") return folders.filter(folder => folder.archived === !!activeTab);

    return folders.filter(
      folder => folder.archived === !!activeTab && folder.folder_name.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }

  function showCreateFolderModal() {
    setModalContent({
      title: "Créer un nouveau dossier",
      content: <CreateFolderModal fetchFoldersFunction={fetchFolders} getUuid={getUuid} />,
    });
    setModalVisible(true);
  }

  function getDuplicateFolderName(folderName) {
    const regex = /#\d+$/;

    if (regex.test(folderName)) {
      const match = folderName.match(/\d+$/);
      return folderName.replace(regex, `#${parseInt(match[0]) + 1}`);
    } else {
      return `${folderName} #1`;
    }
  }

  function showDuplicateFolderModal(folderId, folderName) {
    setModalContent({
      title: "Dupliquer le dossier",
      content: (
        <DuplicateFolderModal
          defaultValue={getDuplicateFolderName(folderName)}
          fetchFoldersFunction={() => fetchFolders()}
          folderId={folderId}
        />
      ),
    });
    setModalVisible(true);
  }

  function getFolderCreationDate(folder) {
    return folder.date_creation.substring(0, 10);
  }

  function toggleOpenedFolderDropdown(key) {
    setOpenedFolderDropdown(openedFolderDropdown === key ? null : key);
  }

  const FolderThumbnail = folder => {
    return (
      <div className='folder-thumbnail-container'>
        <div className='text-center'>Nom du dossier</div>
        <div className='folder-thumbnail'>
          <div className='folder-name'>{folder.folder_name}</div>
          <div className='folder-infos'>
            <p className='label'>Date de création</p>
            <p>{getFolderCreationDate(folder)}</p>
          </div>
          <div className='folder-buttons'>
            <div onClick={() => openFolderDetail(folder.folder_id)}>
              <FilledButton bgColor='#E6CC83' hoverColor='#E6CC83' to={`/mon-compte/mes-dossiers/${folder.folder_id}`}>
                <Pen width='30px' />
              </FilledButton>
              Accéder
            </div>
            <div onClick={() => showDuplicateFolderModal(folder.folder_id, folder.folder_name)}>
              <FilledButton
                bgColor='#E3E3E3'
                hoverColor='#E3E3E3'
                onClick={() => showDuplicateFolderModal(folder.folder_id, folder.folder_name)}>
                <Duplicate width='30px' />
              </FilledButton>
              Dupliquer
            </div>
            {folder.archived ? (
              <div onClick={() => archiveFolder(folder.folder_id, false)}>
                <FilledButton
                  bgColor='#FDB7A9'
                  hoverColor='#FDB7A9'
                  onClick={() => archiveFolder(folder.folder_id, false)}>
                  <Unarchive width='30px' />
                </FilledButton>
                Désarchiver
              </div>
            ) : (
              <div onClick={() => archiveFolder(folder.folder_id, true)}>
                <FilledButton
                  bgColor='#FDB7A9'
                  hoverColor='#FDB7A9'
                  onClick={() => archiveFolder(folder.folder_id, true)}>
                  <Archive width='30px' />
                </FilledButton>
                Archiver
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className='container'>
        <div className='head-row-container'>
          <FilledButton onClick={showCreateFolderModal} className='mid-page-button folders-button'>
            <Add width='30px' /> Créer un nouveau dossier
          </FilledButton>
          <ReviewButton
            modalTitle='Votre avis sur nos dossiers'
            modalText='un dossier'
            link='https://docs.google.com/forms/d/e/1FAIpQLSdRr400a39dx9vsAd8lVmpibeV2_1N1GmsdaA3zbvoMATffGQ/viewform?usp=pp_url'>
            Donnez votre avis sur nos dossiers
          </ReviewButton>
        </div>
        <div className='folders-container'>
          <div className='folders-container-header'>
            <p className={activeTab === 0 ? "active" : ""} onClick={() => setActiveTab(0)}>
              Mes dossiers
            </p>
            <p className={activeTab === 1 ? "active" : ""} onClick={() => setActiveTab(1)}>
              Mes dossiers archivés
            </p>
            <Tuto className='folder-icon-tuto' onClick={() => showTooltip("infos_tab.webp")} />
          </div>
          <div className='search-input-container'>
            <Searchbar
              resetValueTrigger={resetSearchValueTrigger}
              onChange={value => setSearchValue(value)}
              placeholder='Rechercher'
              bgColor='var(--white)'
              className='folders-search-bar'
            />
          </div>
          {width > 1200 && (
            <div className='folders-table'>
              <div className='folders-filters'>
                <div>Ref</div>
                <div>Nom du dossier</div>
                <div>Date</div>
                <div>Actions</div>
              </div>
              <div className='folders-table-body'>
                {visibleFolders.map((folder, key) => {
                  return (
                    <Link
                      className='folder-overview-container'
                      key={key}
                      to={`/mon-compte/mes-dossiers/${folder.folder_id}`}>
                      <div className='item-copy'>
                        <Copy width='11px' color='var(--white)' onClick={e => copyIdToClipboard(e, folder.folder_id)} />
                      </div>
                      <div>{folder.folder_name}</div>
                      <div>{getFolderCreationDate(folder)}</div>
                      <div
                        className='folders-filters-buttons'
                        onClick={event => {
                          event.preventDefault();
                          toggleOpenedFolderDropdown(key);
                        }}>
                        <Bracket
                          rotation={openedFolderDropdown === key ? "0" : "180deg"}
                          width='15px'
                          color='var(--white)'
                          className='dropdown-icon-folder'
                        />
                        <ul className={`dropdown-menu-folder ${openedFolderDropdown === key ? "show" : ""}`}>
                          <li>
                            <button onClick={() => accessFolder(folder.folder_id)}>
                              <Pen width='22px' /> Accéder
                            </button>
                          </li>
                          <li>
                            <button onClick={() => showDuplicateFolderModal(folder.folder_id, folder.folder_name)}>
                              <Duplicate width='22px' /> Dupliquer
                            </button>
                          </li>
                          <li>
                            {folder.archived ? (
                              <button onClick={() => archiveFolder(folder.folder_id, false)}>
                                <Unarchive width='22px' /> Désarchiver
                              </button>
                            ) : (
                              <button onClick={() => archiveFolder(folder.folder_id, true)}>
                                <Archive width='22px' /> Archiver
                              </button>
                            )}
                          </li>
                        </ul>
                      </div>
                    </Link>
                  );
                })}
                {!visibleFolders.length && (
                  <p className='text-lg outfit-semibold my-md text-center'>
                    Aucun résultat ne correspond à votre recherche
                  </p>
                )}
              </div>
            </div>
          )}
          {width <= 1200 && (
            <>
              <ComponentsRow
                slideLength={width > 576 ? 1 : 0}
                contentType='folders'
                Component={FolderThumbnail}
                componentsProps={visibleFolders}
              />
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default AccountFolders;
