import "./AccountToolsTab.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { archiveFinancingPlanApi } from "../../../../../api/FinancingPlanApi";
import { archiveCreditSimulatorApi } from "../../../../../api/CreditSimulatorApi";
import {
  EstimationResponsiveThumbnail,
  EstimationTabCreateButton,
  EstimationTabReviewButton,
  EstimationTabRowItem,
  EstimationsTabDropdownMenu,
  EstimationsTabFilters,
} from "./EstimationsTabComponents";
import {
  FinancingPlanResponsiveThumbnail,
  FinancingPlanTabCreateButton,
  FinancingPlanTabRowItem,
  FinancingPlansTabDropdownMenu,
  FinancingPlansTabFilters,
  FinancingPlansTabReviewButton,
} from "./FinancingPlansTabComponents";
import {
  CreditSimulatorCreateButton,
  CreditSimulatorResponsiveThumbnail,
  CreditSimulatorsTabDropdownMenu,
  CreditSimulatorsTabFilters,
  CreditSimulatorTabReviewButton,
  CreditSimulatorTabRowItem,
} from "./CreditSimulatorsTabComponents";
import AppContext from "../../../../../context/AppContext";
import { useWindowSize } from "@uidotdev/usehooks";
import Searchbar from "../../../../../components/molecules/searchbar/Searchbar";
import DuplicateEstimationModal from "../../../../../components/forms/modals/account/duplicateEstimationModal/DuplicateEstimationModal";
import DuplicateFinancingPlanModal from "../../../../../components/forms/modals/account/duplicateFinancingPlanModal/DuplicateFinancingPlanModal";
import DuplicateCreditSimulatorModal from "../../../../../components/forms/modals/account/duplicateCreditSimulatorModal/DuplicateCreditSimulator";
import { copyToClipboard, createDownloadLink, normalizeString } from "../../../../../utils/Utils";
import { archiveEstimationApi } from "../../../../../api/EstimationApi";
import {
  downloadCreditSimulatorPdfApi,
  downloadEstimationPdfApi,
  downloadFinancingPlanPdfApi,
} from "../../../../../api/DownloadApi";
import Loader from "../../../../../components/organisms/loader/Loader";
import ComponentsRow from "../../../../../components/organisms/componentsRow/ComponentsRow";

//type = estimations / financingPlans / creditSimulators
function AccountToolsTab({ type, items, refreshItems = () => {}, isLoaderVisible }) {
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [visibleItems, setVisibleItems] = useState([]);
  const [openedItemDropdown, setOpenedItemDropdown] = useState(false);
  const [itemsContainerScroll, setItemsContainerScroll] = useState(0);
  const [resetSearchValueTrigger, setResetSearchValueTrigger] = useState(false);
  const { createNotification, setModalContent, setModalVisible, setIsDownloadNotificationLoading } =
    useContext(AppContext);
  const itemsContainerRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const itemsComponents = {
    filters: {
      estimations: EstimationsTabFilters,
      financingPlans: FinancingPlansTabFilters,
      creditSimulators: CreditSimulatorsTabFilters,
    },
    dropdownMenu: {
      estimations: EstimationsTabDropdownMenu,
      financingPlans: FinancingPlansTabDropdownMenu,
      creditSimulators: CreditSimulatorsTabDropdownMenu,
    },
    createButton: {
      estimations: EstimationTabCreateButton,
      financingPlans: FinancingPlanTabCreateButton,
      creditSimulators: CreditSimulatorCreateButton,
    },
    reviewButton: {
      estimations: EstimationTabReviewButton,
      financingPlans: FinancingPlansTabReviewButton,
      creditSimulators: CreditSimulatorTabReviewButton,
    },
    rowItem: {
      estimations: EstimationTabRowItem,
      financingPlans: FinancingPlanTabRowItem,
      creditSimulators: CreditSimulatorTabRowItem,
    },
    responsivethumbnail: {
      estimations: EstimationResponsiveThumbnail,
      financingPlans: FinancingPlanResponsiveThumbnail,
      creditSimulators: CreditSimulatorResponsiveThumbnail,
    },
  };

  const { width } = useWindowSize();

  useEffect(() => {
    refreshItems();
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleCloseDropdownMenu);

    return () => {
      document.removeEventListener("click", handleCloseDropdownMenu);
    };
  }, [dropdownMenuRef.current]);

  useEffect(() => {
    if (!itemsContainerRef.current) return;

    onItemsContainerScroll();

    itemsContainerRef.current?.addEventListener("scroll", onItemsContainerScroll);

    return () => {
      itemsContainerRef.current?.removeEventListener("scroll", onItemsContainerScroll);
    };
  }, [openedItemDropdown]);

  useEffect(() => {
    setResetSearchValueTrigger(!resetSearchValueTrigger);
  }, [activeTab, type]);

  useEffect(() => {
    setVisibleItems(getFilteredItems(items, type));
  }, [activeTab, searchValue, items, type]);

  useEffect(() => {
    setOpenedItemDropdown(false);
  }, [visibleItems]);

  function onItemsContainerScroll() {
    if (!openedItemDropdown) return setItemsContainerScroll(itemsContainerRef.current.scrollTop);

    const elementOffsetBottom =
      itemsContainerRef.current.children[openedItemDropdown.key]?.offsetTop ??
      0 + itemsContainerRef.current.children[openedItemDropdown.key]?.offsetHeight ??
      0;

    const containerOffsetBottom = itemsContainerRef.current.scrollTop + itemsContainerRef.current.offsetHeight;
    let dropdownMenuHeight = 20;
    for (const child of dropdownMenuRef.current.children) dropdownMenuHeight += child.offsetHeight + 10;

    if (elementOffsetBottom + dropdownMenuHeight + 25 > containerOffsetBottom)
      setItemsContainerScroll(itemsContainerRef.current.scrollTop + dropdownMenuHeight + 15);
    else setItemsContainerScroll(itemsContainerRef.current.scrollTop);

    if (
      (openedItemDropdown && elementOffsetBottom < itemsContainerRef.current.scrollTop) ||
      elementOffsetBottom > containerOffsetBottom
    )
      setOpenedItemDropdown(false);
  }

  function handleCloseDropdownMenu() {
    if (!dropdownMenuRef.current) return;

    setOpenedItemDropdown(false);
  }

  function copyIdToClipboard(e, id) {
    e.preventDefault();
    e.stopPropagation();

    copyToClipboard(id, <>La référence a été copiée dans votre presse-papier avec succès.</>, createNotification);
  }

  function getFilteredItems(items, type) {
    if (searchValue === "") return items[type].filter(item => item.archived === !!activeTab);

    let result = [];

    switch (type) {
      case "estimations":
        result = items.estimations.filter(
          estimation =>
            estimation.archived === !!activeTab &&
            (normalizeString(estimation.infos.enseigne).includes(normalizeString(searchValue)) ||
              normalizeString(estimation.infos.nom).includes(normalizeString(searchValue)) ||
              normalizeString(estimation.type.description).includes(normalizeString(searchValue)) ||
              normalizeString(estimation.infos.code_postal).includes(normalizeString(searchValue)) ||
              normalizeString(estimation.infos.rue).includes(normalizeString(searchValue)) ||
              normalizeString(estimation.infos.ville).includes(normalizeString(searchValue))),
        );
        break;
      case "financingPlans":
        result = items.financingPlans.filter(
          plan =>
            plan.archived === !!activeTab &&
            (normalizeString(plan.financing_plan_name).includes(normalizeString(searchValue)) ||
              normalizeString(plan.financing_plan_type === "fdc" ? "Fonds" : "Murs").includes(
                normalizeString(searchValue),
              )),
        );
        break;
      case "creditSimulators":
        result = items.creditSimulators.filter(
          simulator =>
            simulator.archived === !!activeTab &&
            normalizeString(simulator.credit_name).includes(normalizeString(searchValue)),
        );
    }

    setSearchValue(searchValue);

    return result;
  }

  async function archiveItem(itemId, shouldArchive) {
    const itemName = getItemsCategoryName(type);

    try {
      switch (type) {
        case "estimations":
          await archiveEstimationApi(itemId, shouldArchive);
          break;
        case "financingPlans":
          await archiveFinancingPlanApi(itemId, shouldArchive);
          break;
        case "creditSimulators":
          await archiveCreditSimulatorApi(itemId, shouldArchive);
          break;
      }

      refreshItems();
      createNotification(
        <>
          Votre {itemName} a été {shouldArchive ? "archivée" : "désarchivée"} avec succès
        </>,
      );
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors {shouldArchive ? "de l'archivage" : "du désarchivage"} de votre {itemName}.
          Veuillez réessayer
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function getDuplicateItemName(itemName) {
    const regex = /#\d+$/;

    if (regex.test(itemName)) {
      const match = itemName.match(/\d+$/);

      return itemName.replace(regex, `#${parseInt(match[0]) + 1}`);
    } else {
      return `${itemName} #1`;
    }
  }

  function getItemsCategoryName(type, plural) {
    return type === "estimations"
      ? `estimation${plural ? "s" : ""}`
      : type === "financingPlans"
        ? `plan${plural ? "s" : ""} de financement`
        : `simulation${plural ? "s" : ""} de prêt`;
  }

  async function downloadItemPdf(itemId) {
    try {
      switch (type) {
        case "estimations":
          setIsDownloadNotificationLoading(true);
          const resEstimation = await downloadEstimationPdfApi(itemId);

          const linkEstimation = createDownloadLink(resEstimation.data);
          linkEstimation.click();
          linkEstimation.remove();

          createNotification(<>Votre estimation a été téléchargée avec succès</>);
          break;
        case "financingPlans":
          setIsDownloadNotificationLoading(true);
          const resFinancingplan = await downloadFinancingPlanPdfApi(itemId);

          const linkFinancingplan = createDownloadLink(resFinancingplan.data);
          linkFinancingplan.click();
          linkFinancingplan.remove();

          createNotification(<>Votre plan de financement a été téléchargé avec succès</>);
          break;
        case "creditSimulators":
          setIsDownloadNotificationLoading(true);
          const resCreditSimulation = await downloadCreditSimulatorPdfApi(itemId);

          const linkCreditSimulation = createDownloadLink(resCreditSimulation.data);
          linkCreditSimulation.click();
          linkCreditSimulation.remove();

          createNotification(<>Votre simulation de prêt a été téléchargée avec succès</>);
          break;
      }
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du téléchargement de votre {getItemsCategoryName(type)}. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setIsDownloadNotificationLoading(false);
    }
  }

  function showDuplicateItemModal(itemId, itemName) {
    switch (type) {
      case "estimations":
        setModalContent({
          title: "Dupliquer l'estimation",
          content: (
            <DuplicateEstimationModal
              defaultValue={getDuplicateItemName(getEstimationName(itemId))}
              fetchEstimationsFunction={() => refreshItems()}
              estimationId={itemId}
            />
          ),
        });
        break;
      case "financingPlans":
        setModalContent({
          title: "Dupliquer le plan de financement",
          content: (
            <DuplicateFinancingPlanModal
              defaultValue={getDuplicateItemName(itemName)}
              fetchFinancingPlansFunction={() => refreshItems()}
              financingPlanId={itemId}
            />
          ),
        });
        break;
      case "creditSimulators":
        setModalContent({
          title: "Dupliquer la simulation de prêt",
          content: (
            <DuplicateCreditSimulatorModal
              defaultValue={getDuplicateItemName(itemName)}
              fetchCreditSimulatorsFunction={() => refreshItems()}
              creditSimulatorId={itemId}
            />
          ),
        });
    }
    setModalVisible(true);
  }

  function getEstimationName(itemId) {
    const estimation = items.estimations.find(estimation => estimation.id === itemId);
    return estimation.infos.enseigne ?? estimation.infos.nom;
  }

  function getItemDate(item) {
    switch (type) {
      case "estimations":
        return item?.date_creation_estimation?.substring(0, 10);
      case "financingPlans":
      case "creditSimulators":
        return item?.date_creation?.split(" ")[0];
    }
  }

  function toggleOpenedItemDropdown(e, key) {
    e.preventDefault();

    const tmp = openedItemDropdown;
    const top = itemsContainerRef.current.children[key].offsetTop + 60;

    if (openedItemDropdown) setOpenedItemDropdown(false);

    setTimeout(() => {
      if (tmp.key !== key)
        setOpenedItemDropdown({
          item: visibleItems[key],
          key,
          top,
        });
    }, 10);
  }

  function getTableClassName(type) {
    return `${type === "estimations" ? "estimations" : type === "financingPlans" ? "financing-plans" : "credit-simulators"}-table`;
  }

  return (
    <>
      <section className='container'>
        <div className='head-row-container'>
          {itemsComponents.createButton[type]({ width })}
          {itemsComponents.reviewButton[type](
            type === "estimations"
              ? {
                  text: "Donnez votre avis sur nos estimations",
                  link: "https://docs.google.com/forms/d/e/1FAIpQLSd_JMXir1jfCfMBZf8fnTK2DcWWw-8MlL8vQ4epWd_pJ4xWbA/viewform?usp=pp_url",
                }
              : type === "financingPlans"
                ? {
                    text: "Donnez votre avis sur nos plans de financement",
                    link: "https://docs.google.com/forms/d/e/1FAIpQLSf9YmzgyjBxWJnmTLHHSukHcw9dvK1Kscos1jkxdyYaJOrkHA/viewform?usp=pp_url",
                  }
                : type === "creditSimulators"
                  ? {
                      text: "Donnez votre avis sur notre simulateur de prêt",
                      link: "https://docs.google.com/forms/d/e/1FAIpQLScUqDnORP1LLZ4TFgRnNNTRzh_eeHgPY2-5kmPfmwXyQXG5mg/viewform?usp=pp_url",
                    }
                  : {},
          )}
        </div>
        <div className='items-container'>
          <div className='items-container-header'>
            <p className={activeTab === 0 ? "active" : ""} onClick={() => setActiveTab(0)}>
              Mes {getItemsCategoryName(type, 1)}
            </p>
            <p className={activeTab === 1 ? "active" : ""} onClick={() => setActiveTab(1)}>
              Mes {getItemsCategoryName(type, 1)} archivé{type !== "financingPlans" && "e"}s
            </p>
          </div>
          <div className='search-input-container'>
            <Searchbar
              resetValueTrigger={resetSearchValueTrigger}
              onChange={value => setSearchValue(value)}
              placeholder='Rechercher'
              bgColor='var(--white)'
              className='items-search-bar'
            />
          </div>
          {width > 1200 && (
            <div className={`items-table ${getTableClassName(type)}`}>
              <div className='items-filters'>{itemsComponents.filters[type]()}</div>
              <ul
                ref={dropdownMenuRef}
                className={`dropdown-menu-item ${openedItemDropdown ? "show" : ""}`}
                style={{ "--top": openedItemDropdown?.top - itemsContainerScroll + "px" }}>
                {itemsComponents.dropdownMenu[type]({
                  openedItemDropdown,
                  downloadItemPdf,
                  showDuplicateItemModal,
                  archiveItem,
                })}
              </ul>
              <div className='items-table-body' ref={itemsContainerRef}>
                {visibleItems.map((item, key) =>
                  itemsComponents.rowItem[type]({
                    index: key,
                    item,
                    date: getItemDate(item),
                    copyIdToClipboard,
                    toggleOpenedItemDropdown,
                    openedItemDropdown,
                    showStatus: true,
                  }),
                )}
                {!visibleItems.length && !isLoaderVisible && (
                  <p className='text-lg outfit-semibold my-md text-center'>
                    Aucun résultat ne correspond à votre recherche
                  </p>
                )}
                <Loader visible={isLoaderVisible} className='tools-loader' preventScrolling={false} />
              </div>
            </div>
          )}
        </div>
      </section>
      {width <= 1200 && (
        <>
          <ComponentsRow
            slideLength={width > 576 ? 1 : 0}
            contentType='estimations'
            Component={itemsComponents.responsivethumbnail[type]}
            componentsProps={visibleItems.map(item => ({
              item,
              archiveItem,
              showDuplicateItemModal,
              downloadItemPdf,
              date: getItemDate(item),
            }))}
          />
        </>
      )}
    </>
  );
}

export default AccountToolsTab;
