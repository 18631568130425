import React, { useContext, useEffect, useRef, useState } from "react";
import "./DetailParcours.css";
import ParcoursMc from "../../../../components/parcours/parcoursMc/ParcoursMc";
import FilledButton from "../../../../components/molecules/buttons/filledButton/FilledButton";
import Bracket from "../../../../components/atoms/icons/general/bracket/Bracket";
import TooltipModal from "../../../../components/organisms/tooltipModal/TooltipModal";
import { ParcoursContext } from "../../../../context/ParcoursContext";
import Breadcrumbs from "../../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../../components/molecules/banner/Banner";
import {
  fetchAllActivitesByIdApi,
  fetchCAInputs,
  fetchDropdownValuesApi,
  fetchTypesCommerceApi,
} from "../../../../api/ParcoursApi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fetchSirenDataApi, fetchTokenApi } from "../../../../api/InseeApi";
import { formatDate, formatNumberWithSpaces, isFormattedDate } from "../../../../utils/Utils";
import StepsRow from "../../../../components/organisms/stepsRow/StepsRow";
import AppContext from "../../../../context/AppContext";
import SecondaryParcours from "../../../../components/parcours/parcoursFdc/SecondaryParcours";
import { fetchEstimationApi, updateEstimationAPI, validateEstimationApi } from "../../../../api/EstimationApi";
import { Link } from "react-router-dom";
import ArrowSm from "../../../../components/atoms/icons/general/arrowSm/ArrowSm";
import ParcoursFdc from "../../../../components/parcours/parcoursFdc/ParcoursFdc";
import UnfilledButton from "../../../../components/molecules/buttons/unfilledButton/UnfilledButton";

function DetailParcours() {
  const [toolTipVisible, setToolTipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("");
  const [step, setStep] = useState(1);
  const [estimationValues, setEstimationValues] = useState({});
  const [goToStep, setGoToStep] = useState(0);
  const [typesCommerce, setTypesCommerce] = useState([]);
  // 1: affaires concernées par le chiffre d’affaire hébergement + restauration
  // 2: affaires concernées par le total des commissions nettes
  // 3: affaires concernées par l’extraction
  // 4: affaires concernées par le % liquide
  // 5: affaires concernées par la réputation en ligne de Booking (càd : faire apparaître la case des com° nettes sur l'étape 4 du parcours)
  // 6: affaires pour lesquelles la fourchette EBE sur CA n'est pas comptabilisée et n'apparaît pas dans l'estimation en fin de rapport PDF
  // 7: affaires pour lesquelles la fourchette CA n'est pas comptabilisée et n'apparaît pas dans l'estimation en fin de rapport PDF
  // 8: hôtels
  // 9: CHR (SAUF Hôtel bureau ET Hôtel meublé - Appart'hôtel ET Résidence hôtelière)
  const [activites, setActivites] = useState([]);
  const [parcoursType, setParcoursType] = useState(1); // fdc = 1, tds = 2, mc = 3
  const [displayedRaisonsSociales, setDisplayedRaisonsSociales] = useState([]);
  const [inputsNames, setInputsNames] = useState([]);
  const [raisonsSociales, setRaisonsSociales] = useState([]);
  const [raisonSocialeWatcher, setRaisonSocialeWatcher] = useState("");
  const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState(false);
  const [stepsSummary, setStepsSummary] = useState([]);
  const [sirenAccessToken, setSirenAccessToken] = useState("");
  const [CAInputs, setCAInputs] = useState([]);
  const [mainBilanId, setMainBilanId] = useState(null);
  const submitFormsFdc = useRef(new Array(14).fill(null));
  const submitFormsMc = useRef(new Array(9).fill(null));
  const resetFormsRef = useRef(new Array(14).fill(() => {}));
  const parcoursTopRef = useRef(null);
  const tooltipModalRef = useRef(null);
  const { parcoursTypeParam, estimationIdParam } = useParams();
  const { createNotification, setAppLoaderVisible } = useContext(AppContext);
  const [URLSearchParams, setURLSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchActivites();
    fetchAccessToken();

    setStep(1);

    switch (parcoursTypeParam) {
      case "fonds-de-commerce":
        setParcoursType(1);
        break;
      case "titres-de-societe":
        setParcoursType(2);
        break;
      case "murs-commerciaux":
        setParcoursType(3);
        break;
      case "secondaire":
        setParcoursType(4);
    }
  }, [parcoursTypeParam]);

  useEffect(() => {
    if (URLSearchParams.get("nouveau") === null) return;

    URLSearchParams.delete("nouveau");
    setURLSearchParams(URLSearchParams);
    resetParcours();
  }, [URLSearchParams]);

  useEffect(() => {
    if (!estimationIdParam || estimationIdParam === "null") return resetParcours();

    setAppLoaderVisible(true);

    fetchEstimation(estimationIdParam);
  }, [estimationIdParam, parcoursTypeParam]);

  useEffect(() => {
    focusInput(inputsNames[step - 1]);
    setIsConfirmButtonLoading(false);
  }, [step, parcoursType, inputsNames]);

  useEffect(() => {
    const timeout = setTimeout(() => filterDisplayedRaisonsSociales(), 500);

    return () => clearTimeout(timeout);
  }, [raisonsSociales, raisonSocialeWatcher]);

  //fetching data

  async function fetchAccessToken() {
    try {
      const token = (await fetchTokenApi()).data;
      setSirenAccessToken(token);
    } catch (e) {}
  }

  async function fetchEstimation(estimationId) {
    try {
      const response = await fetchEstimationApi(estimationId);
      setEstimationValues(response.data.estimation);
      setMainBilanId(
        response.data.related_estimation_id ? response.data.related_estimation_id : response.data.estimation.id,
      );

      if (response?.data?.estimation?.infos?.activite?.id)
        await fetchTypesCommerce(response.data.estimation.infos.activite.id, response.data.estimation.id);

      if (parcoursType === 4) setStep(1);
      if (response.data.estimation.type.id === 3) {
        if (response.data.estimation.step < 9) setStep(Number(response.data.estimation.step) + 1);
      } else if (response.data.estimation.step < 13) setStep(Number(response.data.estimation.step) + 1);
    } catch (e) {
      createNotification(
        <>
          Une erreur est survenue lors de la récupération de votre estimation.{" "}
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => window.location.reload()}>
            Veuillez réessayer
          </span>
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
    setAppLoaderVisible(false);
  }

  async function fetchActivites() {
    try {
      setActivites((await fetchAllActivitesByIdApi()).data.sort((a, b) => a.description.localeCompare(b.description)));
    } catch (e) {
      createNotification(
        <>
          Une erreur est survenue lors de la récupération de données importantes.{" "}
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => window.location.reload()}>
            Veuillez réessayer
          </span>
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function fetchTypesCommerce(commerceId) {
    try {
      let res = (await fetchTypesCommerceApi(commerceId)).data;

      if (res.affaires) setTypesCommerce(res.affaires.map(affaire => affaire.id));
      else setTypesCommerce([]);

      res = (await fetchCAInputs(commerceId)).data;

      setCAInputs(res);
    } catch (e) {
      createNotification(
        <>Une erreur est survenue lors de la récupération d'informations. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function fetchDropdownValues(...names) {
    const result = {};
    for (const name of names) {
      try {
        result[name] = (await fetchDropdownValuesApi(name)).data;
      } catch (error) {
        createNotification(
          <>Une erreur est survenue lors de la récupération de données importantes. Veuillez réessayer.</>,
          "var(--red)",
          "var(--dark-blue)",
        );
      }
    }

    return result;
  }

  // send / sort data
  async function postValues(values) {
    setIsConfirmButtonLoading(true);

    try {
      const payload = {
        ...{
          id: getEstimationId(),
          step: Math.max(step, estimationValues.step != undefined ? estimationValues.step : 0),
          type: {
            id: parcoursType,
          },
          ...values,
        },
      };
      if (!getEstimationId()) delete payload.id;

      const response = await updateEstimationAPI(payload);

      if (!estimationIdParam)
        window.history.replaceState(null, null, `/detail-parcours/${parcoursTypeParam}/${response.data.estimation.id}`);
      if (response.data?.estimation?.infos?.activite?.id && !typesCommerce.length)
        fetchTypesCommerce(response.data.estimation.infos.activite.id, response.data.estimation.id);

      setEstimationValues(response.data.estimation);

      if (isLastStep()) {
        await validateEstimationApi(response.data.estimation.id);
        navigate(`/synthese/${response.data.estimation.id}`);
      }

      return response.data.estimation.id;
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors de la sauvegarde de l'étape {step}.{" "}
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => setStep(step)}>
            Veuillez réessayer
          </span>
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
      throw error;
    } finally {
      setIsConfirmButtonLoading(false);
    }
  }

  async function submitStep() {
    if (parcoursType === 3) await submitFormsMc?.current[step]();
    else await submitFormsFdc?.current[step]();
  }

  function fillInputsFromEstimationValues(parents, setValue, estimation, exceptions = [], prefix = "") {
    if (!estimation) return;

    parents.forEach(parent => {
      if (estimation[parent]) {
        Object.keys(estimation[parent]).forEach(key => {
          if (!exceptions.includes(key)) {
            const value = estimation[parent][key];

            if (value && typeof value === "object" && "id" in value && "description" in value) {
              setValue(`${prefix}${parent}.${key}.id`, value.id ? value.id + "" : "_null");
            } else if (value && isFormattedDate(value)) {
              setValue(`${prefix}${parent}.${key}`, formatDate(value));
            } else if (typeof value !== "object") setValue(`${prefix}${parent}.${key}`, value);
          }
        });
      }
    });
  }

  // parcours navigation
  function handleTabs(step, index, submitFunction) {
    return {
      onFocus: () => {
        if (Number(step) === index + 1) {
          setStep(index);
        }
      },
      onKeyDown: e => {
        if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
          e.preventDefault();
          submitFunction();
        }
      },
    };
  }

  function focusInput(idInput) {
    parcoursTopRef.current && parcoursTopRef.current.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      document.getElementById(idInput)?.focus([{ preventScroll: true }]);
    }, 400);
  }

  function isStepChecked(actualStep) {
    return (actualStep <= estimationValues?.step || actualStep < step) && actualStep != step;
  }

  function changeStepFromSummary(goToStep) {
    if (goToStep < step) return setStep(goToStep);
    submitStep();
    setGoToStep(Math.min(goToStep, estimationValues?.step != undefined ? Number(estimationValues.step) + 1 : 0));
    setTimeout(() => {
      setGoToStep(0);
    }, 500);
  }

  async function saveAndQuit() {
    await submitStep();
    navigate(`/synthese/${estimationValues.id}`);
  }

  function getStepToGo() {
    return document.getElementById("goToStep").value;
  }

  function previousStep() {
    if (step > 1) {
      setStep(step - 1);
    }
  }

  function isLastStep() {
    if (parcoursType === 4) return step >= 4;
    if (parcoursType === 3) return step >= 9;
    return step >= 13;
  }

  function moveToNextStep(index) {
    if (isLastStep()) return;

    setStep(index + 1);
    if (inputsNames[index + 1] && document.getElementById(inputsNames[index + 1]))
      document.getElementById(inputsNames[index + 1]).focus({ preventScroll: true });
  }

  // misc

  async function filterDisplayedRaisonsSociales() {
    if (raisonSocialeWatcher === undefined || !sirenAccessToken) return;

    if (!raisonSocialeWatcher.length) return setDisplayedRaisonsSociales([]);

    try {
      const response = (await fetchSirenDataApi(raisonSocialeWatcher, sirenAccessToken)).data.etablissements;
      setDisplayedRaisonsSociales(
        response.map((element, key) => {
          const adresse = element.adresseEtablissement;
          return {
            id: key,
            nom: element.uniteLegale.denominationUniteLegale || "",
            siret: element.siret.replace(/(.{3})/g, "$1 ") || "",
            naf: element.uniteLegale.activitePrincipaleUniteLegale || "",
            rue: `${adresse.numeroVoieEtablissement || ""} ${adresse.typeVoieEtablissement || ""} ${
              adresse.libelleVoieEtablissement || ""
            }`.trim(),
            code_postal: adresse.codePostalEtablissement || "",
            ville: adresse.libelleCommuneEtablissement || "",
          };
        }),
      );
    } catch (e) {
      if (e.response?.status !== 404)
        createNotification(
          <>
            La base de données Siren du Gouvernement est temporairement inaccessible. Rassurez-vous, cela n’impacte pas
            votre estimation. Il vous suffit de renseigner manuellement les données de l’entreprise (ici la raison
            sociale et à l’étape suivante, l’enseigne, l’adresse et le N° de Siret.
          </>,
          "var(--red)",
          "var(--dark-blue)",
        );
    }
  }

  function selectRaisonSociale(id) {
    const raisonSociale = displayedRaisonsSociales.find(element => element?.id === id);

    setEstimationValues(prevState => {
      return {
        ...prevState,
        infos: {
          raison_sociale: raisonSociale?.nom,
          enseigne: raisonSociale?.nom,
          siret: raisonSociale?.siret,
          ville: raisonSociale?.ville,
          rue: raisonSociale?.rue,
          code_postal: raisonSociale?.code_postal,
        },
      };
    });

    setDisplayedRaisonsSociales([]);
    moveToNextStep(2);
  }

  function getEstimationId() {
    if (estimationIdParam) return estimationIdParam;
    return estimationValues?.id;
  }

  function hasLocalDependance(estimationValues) {
    return (estimationValues?.emplacement?.qualite_secteur?.id &&
      estimationValues?.emplacement?.qualite_implantation_rue?.id) ||
      estimationValues?.step < 8
      ? true
      : false;
  }

  function resetParcours() {
    setStep(1);
    resetFormsRef?.current?.forEach(reset => reset());
    setRaisonSocialeWatcher("");
    setEstimationValues({});
    setTypesCommerce([]);
    setCAInputs([]);
  }

  return (
    <>
      <TooltipModal
        isVisible={toolTipVisible}
        tooltip={tooltipContent}
        setTooltip={setTooltipContent}
        setIsVisible={setToolTipVisible}
        ref={tooltipModalRef}
      />
      <ParcoursContext.Provider
        value={{
          step,
          handleTabs,
          resetFormsRef,
          parcoursTopRef,
          parcoursType,
          getStepToGo,
          setStepsSummary,
          fetchDropdownValues,
          estimationValues,
          fillInputsFromEstimationValues,
          setRaisonSocialeWatcher,
          inputsNames,
          setInputsNames,
          typesCommerce,
          setTypesCommerce,
          raisonsSociales,
          setRaisonsSociales,
          activites,
          setStep,
          moveToNextStep,
          postValues,
          submitStep,
          submitFormsFdc,
          submitFormsMc,
          parcoursType,
          hasLocalDependance,
          fillInputsFromEstimationValues,
          goToStep,
          setGoToStep,
          CAInputs,
        }}>
        <div className='page-container'>
          <section className='container'>
            <Breadcrumbs
              routes={[
                {
                  name: "Accueil",
                  to: "/",
                },
                {
                  name: "Estimer: Parcours d'estimation",
                  to: `/detail-parcours/${parcoursTypeParam}`,
                },
              ]}
            />
            <Banner
              title='Evaluation en ligne'
              subtitle={
                parcoursType === 4 ? (
                  <Link className='link-with-arrow' to={`/synthese/${mainBilanId}`}>
                    <ArrowSm /> Accéder à la synthèse
                  </Link>
                ) : (
                  <Link className='link-with-arrow' to='/mon-compte/mes-outils/mes-estimations'>
                    <ArrowSm />
                    Accéder à mes estimations
                  </Link>
                )
              }
            />
          </section>
          <input type='hidden' value={goToStep} id='goToStep' />
          <div ref={parcoursTopRef} />
          <StepsRow
            stepsGroup={stepsSummary}
            actualStep={step}
            onStepClick={changeStepFromSummary}
            isStepChecked={isStepChecked}
          />
          <section className='container relative'>
            {parcoursType === 4 ? <SecondaryParcours /> : parcoursType === 3 ? <ParcoursMc /> : <ParcoursFdc />}
            <div
              className={
                "carousel-buttons " + (parcoursType !== 4 && step == 2 && parcoursType !== 3 ? " step-2-buttons" : "")
              }>
              {step == 1 ? (
                <div />
              ) : (
                <FilledButton height='50px' onClick={previousStep}>
                  <Bracket rotation='270deg' width='18px' />
                  Etape précédente
                </FilledButton>
              )}
              {estimationValues?.validated && step > 1 && step < 13 && (
                <>
                  <UnfilledButton height='50px' onClick={saveAndQuit}>
                    Valider et terminer
                  </UnfilledButton>
                </>
              )}
              <FilledButton
                height='50px'
                bgColor='var(--gold)'
                color='var(--dark-blue)'
                onClick={submitStep}
                isLoading={isConfirmButtonLoading}>
                {isLastStep() ? (
                  "Valider"
                ) : (
                  <>
                    Valider et continuer
                    <Bracket color='var(--dark-blue)' rotation='90deg' width='18px' />
                  </>
                )}
              </FilledButton>
            </div>
            <div className='raisons-sociales-dropdown'>
              <ul
                className={
                  "raisons-sociales-container" + (displayedRaisonsSociales?.length && step == 2 ? "" : " h-none")
                }>
                {displayedRaisonsSociales.map((raisonSociale, key) => (
                  <li
                    className='raison-sociale-container'
                    key={key}
                    onClick={() => selectRaisonSociale(raisonSociale.id)}>
                    <p className='raison-sociale-title'>{raisonSociale.nom}</p>
                    <div className='row'>
                      <p>
                        <span className='raison-sociale-label'>SIRET :</span>{" "}
                        <span className='raison-sociale-value'>{raisonSociale.siret}</span>
                      </p>
                      <p>
                        <span className='raison-sociale-label'>NAF :</span>{" "}
                        <span className='raison-sociale-value'>{raisonSociale.naf}</span>
                      </p>
                    </div>
                    <p className='raison-sociale-label'>ADRESSE</p>
                    <p className='raison-sociale-value'>{raisonSociale.rue}</p>
                    <p className='raison-sociale-value'>{raisonSociale.code_postal + " " + raisonSociale.ville}</p>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
      </ParcoursContext.Provider>
    </>
  );
}

export default DetailParcours;
