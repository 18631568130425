import { Link } from "react-router-dom";
import Archive from "../../../../../components/atoms/icons/account/archive/Archive";
import Duplicate from "../../../../../components/atoms/icons/account/duplicate/Duplicate";
import Pen from "../../../../../components/atoms/icons/account/pen/Pen";
import Unarchive from "../../../../../components/atoms/icons/account/unarchive/Unarchive";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../components/atoms/icons/general/add/Add";
import Bracket from "../../../../../components/atoms/icons/general/bracket/Bracket";
import Download from "../../../../../components/atoms/icons/general/download/Download";
import LinkIcon from "../../../../../components/atoms/icons/account/linkIcon/LinkIcon";
import ReviewButton from "../../../../../components/molecules/buttons/reviewButton/ReviewButton";
import Shop from "../../../../../components/atoms/icons/header/shop/Shop";
import Building from "../../../../../components/atoms/icons/header/building/Building";
import Copy from "../../../../../components/atoms/icons/account/copy/Copy";
import Trash from "../../../../../components/atoms/icons/general/trash/Trash";
import { getShortedString } from "../../../../../utils/Utils";

export const FinancingPlansTabFilters = () => (
  <>
    <div>Ref</div>
    <div>Intitulé</div>
    <div>Type</div>
    <div />
    <div>Date</div>
    <div />
    <div>Actions</div>
  </>
);

export const FinancingPlansTabReviewButton = ({ text, link }) => (
  <ReviewButton modalTitle='Votre avis sur nos plans de financement' modalText='un plan de financement' link={link}>
    {text}
  </ReviewButton>
);

export const FinancingPlansTabDropdownMenu = ({
  openedItemDropdown,
  downloadItemPdf,
  showDuplicateItemModal,
  archiveItem,
  removeFromFolder,
  removeFromDeliverable,
}) => (
  <>
    <li>
      <Link
        to={`/plan-financement/${openedItemDropdown?.item?.financing_plan_type}/${openedItemDropdown?.item?.financing_plan_id}`}>
        <Pen width='18px' />
        Modifier
      </Link>
    </li>
    {downloadItemPdf && (
      <li>
        <button onClick={() => downloadItemPdf(openedItemDropdown?.item?.financing_plan_id)}>
          <Download color='var(--dark-blue)' width='18px' />
          Télécharger
        </button>
      </li>
    )}
    {showDuplicateItemModal && (
      <li>
        <button
          onClick={() =>
            showDuplicateItemModal(
              openedItemDropdown?.item?.financing_plan_id,
              openedItemDropdown?.item?.financing_plan_name,
            )
          }>
          <Duplicate width='18px' />
          Dupliquer
        </button>
      </li>
    )}
    {removeFromFolder && (
      <li>
        <button onClick={() => removeFromFolder(openedItemDropdown?.item?.financing_plan_id)}>
          <Trash width='18px' />
          Retirer du dossier
        </button>
      </li>
    )}
    {removeFromDeliverable && (
      <li>
        <button onClick={() => removeFromDeliverable(openedItemDropdown?.item?.financing_plan_id)}>
          <Trash width='18px' />
          Retirer du livrable
        </button>
      </li>
    )}
    {archiveItem &&
      (openedItemDropdown?.item?.archived ? (
        <li>
          <button onClick={() => archiveItem(openedItemDropdown?.item?.financing_plan_id, false)}>
            <Unarchive width='18px' />
            Désarchiver
          </button>
        </li>
      ) : (
        <li>
          <button onClick={() => archiveItem(openedItemDropdown?.item?.financing_plan_id, true)}>
            <Archive width='18px' />
            Archiver
          </button>
        </li>
      ))}
  </>
);

export const FinancingPlanTabCreateButton = ({ width }) => (
  <FilledButton className='mid-page-button items-button' to='/plan-financement/fdc'>
    <Add width='30px' /> Créer un plan de financement
  </FilledButton>
);

export const FinancingPlanTabRowItem = ({
  index,
  item,
  date,
  toggleOpenedItemDropdown,
  copyIdToClipboard = () => {},
  openedItemDropdown,
}) =>
  item && (
    <Link
      className='item-overview-container'
      key={index}
      to={`/plan-financement/${item.financing_plan_type}/${item.financing_plan_id}`}>
      <div className='item-copy'>
        <Copy width='11px' color='var(--white)' onClick={e => copyIdToClipboard(e, item.financing_plan_id)} />
      </div>
      <div>{getShortedString(item.financing_plan_name, 27)}</div>
      <div className={`estimation-type-${item.financing_plan_type === "fdc" ? "fonds" : "murs"}`}>
        {item.financing_plan_type === "fdc" ? (
          <Shop width='16px' color='var(--white)' className='mr-sm' />
        ) : (
          <Building width='16px' color='var(--white)' className='mr-sm' />
        )}
        {item.financing_plan_type === "fdc" ? "Fonds" : "Murs"}
      </div>
      <div />
      <div>{date}</div>
      {item.estimation_data ? (
        <div className='financing-plan-link-icon-container'>
          <div className='financing-plan-link-icon'>
            <LinkIcon width='11px' />
          </div>
          <div className='financing-plan-link-tooltip'>
            Ce plan de financement est lié à l'estimation {item.estimation_data.estimation_name}
          </div>
        </div>
      ) : (
        <div />
      )}
      {toggleOpenedItemDropdown && (
        <div className='item-filters-buttons' onClick={e => toggleOpenedItemDropdown(e, index)}>
          <Bracket
            rotation={openedItemDropdown?.key === index ? "0" : "180deg"}
            width='15px'
            color='var(--white)'
            className='dropdown-icon-item'
          />
        </div>
      )}
    </Link>
  );

export const FinancingPlanResponsiveThumbnail = ({
  item,
  date,
  archiveItem = () => {},
  downloadItemPdf = () => {},
  showDuplicateItemModal = () => {},
}) =>
  item && (
    <div className='item-thumbnail-container'>
      <div className='text-center'>Intitulé</div>
      <div className='item-thumbnail'>
        <div className='item-enseigne'>{item.financing_plan_name}</div>
        <div className='item-infos'>
          <p className='label'>Type</p>
          <p>{item.financing_plan_type === "fdc" ? "Fonds de commerce" : "Murs commerciaux"}</p>
          <p className='label'>Date</p>
          <p>{date}</p>
        </div>
        <div className='item-buttons'>
          <Link to={`/plan-financement/${item.financing_plan_type}/${item.financing_plan_id}`}>
            <FilledButton color='var(--dark-blue)' bgColor='#E6CC83' hoverColor='#E6CC83'>
              <Pen width='30px' />
            </FilledButton>
            Accéder
          </Link>
          <div onClick={() => downloadItemPdf(item.financing_plan_id)}>
            <div>
              <FilledButton bgColor='#B8C7FF' hoverColor='#B8C7FF'>
                <Download color='var(--dark-blue)' width='30px' />
              </FilledButton>
              Télécharger
            </div>
          </div>
          <div onClick={() => showDuplicateItemModal(item.financing_plan_id, item.financing_plan_name)}>
            <FilledButton bgColor='#E3E3E3' hoverColor='#E3E3E3'>
              <Duplicate width='30px' />
            </FilledButton>
            Dupliquer
          </div>
          {item.archived ? (
            <>
              <div>
                <FilledButton
                  bgColor='#FDB7A9'
                  hoverColor='#FDB7A9'
                  onClick={() => archiveItem(item.financing_plan_id, false)}>
                  <Unarchive width='30px' />
                </FilledButton>
                Désarchiver
              </div>
            </>
          ) : (
            <div>
              <FilledButton
                bgColor='#FDB7A9'
                hoverColor='#FDB7A9'
                onClick={() => archiveItem(item.financing_plan_id, true)}>
                <Archive width='30px' />
              </FilledButton>
              Archiver
            </div>
          )}
        </div>
      </div>
    </div>
  );
