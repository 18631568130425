import { Link } from "react-router-dom";
import Pen from "../../../../../components/atoms/icons/account/pen/Pen";
import Duplicate from "../../../../../components/atoms/icons/account/duplicate/Duplicate";
import Unarchive from "../../../../../components/atoms/icons/account/unarchive/Unarchive";
import Archive from "../../../../../components/atoms/icons/account/archive/Archive";
import Add from "../../../../../components/atoms/icons/general/add/Add";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import Bracket from "../../../../../components/atoms/icons/general/bracket/Bracket";
import Download from "../../../../../components/atoms/icons/general/download/Download";
import ReviewButton from "../../../../../components/molecules/buttons/reviewButton/ReviewButton";
import Copy from "../../../../../components/atoms/icons/account/copy/Copy";
import Trash from "../../../../../components/atoms/icons/general/trash/Trash";
import { getShortedString } from "../../../../../utils/Utils";

export const CreditSimulatorsTabFilters = () => {
  return (
    <>
      <div>Ref</div>
      <div>Intitulé</div>
      <div>Date</div>
      <div />
      <div>Actions</div>
    </>
  );
};

export const CreditSimulatorTabReviewButton = ({ text, link }) => (
  <ReviewButton modalTitle='Votre avis sur nos simulateurs de crédit' modalText='un simulateur de crédit' link={link}>
    {text}
  </ReviewButton>
);

export const CreditSimulatorsTabDropdownMenu = ({
  openedItemDropdown,
  downloadItemPdf,
  showDuplicateItemModal,
  archiveItem,
  removeFromFolder,
  removeFromDeliverable,
}) => (
  <>
    <li>
      <Link to={`/simulateur-credit/${openedItemDropdown?.item?.credit_id}`}>
        <Pen width='18px' />
        Modifier
      </Link>
    </li>
    {downloadItemPdf && (
      <li>
        <button onClick={() => downloadItemPdf(openedItemDropdown?.item?.credit_id)}>
          <Download color='var(--dark-blue)' width='18px' />
          Télécharger
        </button>
      </li>
    )}
    {showDuplicateItemModal && (
      <li>
        <button
          onClick={() =>
            showDuplicateItemModal(openedItemDropdown?.item?.credit_id, openedItemDropdown?.item?.credit_name)
          }>
          <Duplicate width='18px' />
          Dupliquer
        </button>
      </li>
    )}
    {removeFromFolder && (
      <li>
        <button onClick={() => removeFromFolder(openedItemDropdown?.item?.credit_id)}>
          <Trash width='18px' />
          Retirer du dossier
        </button>
      </li>
    )}
    {removeFromDeliverable && (
      <li>
        <button onClick={() => removeFromDeliverable(openedItemDropdown?.item?.credit_id)}>
          <Trash width='18px' />
          Retirer du livrable
        </button>
      </li>
    )}
    {archiveItem &&
      (openedItemDropdown?.item?.archived ? (
        <li>
          <button onClick={() => archiveItem(openedItemDropdown?.item?.credit_id, false)}>
            <Unarchive width='18px' />
            Désarchiver
          </button>
        </li>
      ) : (
        <li>
          <button onClick={() => archiveItem(openedItemDropdown?.item.credit_id, true)}>
            <Archive width='18px' />
            Archiver
          </button>
        </li>
      ))}
  </>
);

export const CreditSimulatorCreateButton = ({ width }) => (
  <FilledButton className='mid-page-button items-button' to='/simulateur-credit'>
    <Add width='30px' /> Créer une simulation de prêt
  </FilledButton>
);

export const CreditSimulatorTabRowItem = ({
  index,
  item,
  date,
  toggleOpenedItemDropdown,
  copyIdToClipboard = () => {},
  openedItemDropdown,
}) =>
  item && (
    <Link key={index} className='item-overview-container' to={`/simulateur-credit/${item.credit_id}`}>
      <div className='item-copy' onClick={e => copyIdToClipboard(e, item.credit_id)}>
        <Copy width='11px' color='var(--white)' onClick={e => copyIdToClipboard(e, item.credit_id)} />
      </div>
      <div>{getShortedString(item.credit_name, 55)}</div>
      <div>{date}</div>
      <div />
      {toggleOpenedItemDropdown && (
        <div className='item-filters-buttons' onClick={e => toggleOpenedItemDropdown(e, index)}>
          <Bracket
            rotation={openedItemDropdown?.key === index ? "0" : "180deg"}
            width='15px'
            color='var(--white)'
            className='dropdown-icon-item'
          />
        </div>
      )}
    </Link>
  );

export const CreditSimulatorResponsiveThumbnail = ({
  item,
  date,
  archiveItem = () => {},
  downloadItemPdf = () => {},
  showDuplicateItemModal = () => {},
}) =>
  item && (
    <div className='item-thumbnail-container'>
      <div className='text-center'>Intitulé</div>
      <div className='item-thumbnail'>
        <div className='item-enseigne'>{item.credit_name}</div>
        <div className='item-infos'>
          <p className='label'>Date</p>
          <p>{date}</p>
        </div>
        <div className='item-buttons'>
          <Link to={`/simulateur-credit/${item.credit_id}`}>
            <FilledButton color='var(--dark-blue)' bgColor='#E6CC83' hoverColor='#E6CC83'>
              <Pen width='30px' />
            </FilledButton>
            Modifier
          </Link>
          <div onClick={() => downloadItemPdf(item.credit_id)}>
            <div>
              <FilledButton bgColor='#B8C7FF' hoverColor='#B8C7FF'>
                <Download color='var(--dark-blue)' width='30px' />
              </FilledButton>
              Télécharger
            </div>
          </div>
          <div onClick={() => showDuplicateItemModal(item.credit_id, item.credit_name)}>
            <FilledButton bgColor='#E3E3E3' hoverColor='#E3E3E3'>
              <Duplicate width='30px' />
            </FilledButton>
            Dupliquer
          </div>
          {item.archived ? (
            <>
              <div>
                <FilledButton bgColor='#FDB7A9' hoverColor='#FDB7A9' onClick={() => archiveItem(item.credit_id, false)}>
                  <Unarchive width='30px' />
                </FilledButton>
                Désarchiver
              </div>
            </>
          ) : (
            <div>
              <FilledButton bgColor='#FDB7A9' hoverColor='#FDB7A9' onClick={() => archiveItem(item.credit_id, true)}>
                <Archive width='30px' />
              </FilledButton>
              Archiver
            </div>
          )}
        </div>
      </div>
    </div>
  );
