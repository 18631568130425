import React from "react";

function Number({ width = "70px", className = "", number }) {
  return (
    number && (
      <img
        src={require(`../../../../assets/icons/numbers/number_${number}.svg`)}
        style={{ width: width }}
        className={className}
      />
    )
  );
}

export default Number;
