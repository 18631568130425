import "./SummaryPdf.css";
import React, { Fragment } from "react";

function SummaryPdf({ summaryChapters, startIndex = 0 }) {
  function generateDots(title) {
    const maxLength = 180;
    const numDots = maxLength - title.length;
    return ".".repeat(Math.max(0, numDots));
  }

  function getChapterNumber(summaryChapters, index) {
    const annexIndex = summaryChapters.findIndex(chapter => chapter.title === "Annexes");

    if (index < annexIndex) return `${index + 1}. ${summaryChapters[index].title}`;

    if (index > annexIndex) return `${index}. ${summaryChapters[index].title}`;

    return "Annexes";
  }

  return (
    <>
      {summaryChapters.map((summaryChapter, index) => (
        <Fragment key={`chapter-${index}`}>
          <div possible-page-break='true' />
          <a href={"#" + generateHrefPdf(summaryChapter.title)} className='chapter-title-container'>
            <span className='chapter-title'>{getChapterNumber(summaryChapters, index)}</span>
            <span className='summary-dots-line'>
              {generateDots(`${startIndex + index + 1}. ${summaryChapter.title}`)}
            </span>
            <span>{summaryChapter.page}</span>
          </a>
          {summaryChapter.subItems &&
            summaryChapter.subItems.map((item, subIndex) => (
              <Fragment key={subIndex}>
                <a
                  href={"#" + generateHrefPdf(item.subtitle)}
                  key={`chapter-${index}-subitem-${subIndex}`}
                  className='chapter-subtitle-container'>
                  <span className='subtitle-bullet-point'>•</span>
                  <span>{item.subtitle}</span>
                  <span className='summary-dots-line'>{generateDots(item.subtitle)}</span>
                  <span>{item.page}</span>
                </a>
                <div possible-page-break='true' />
              </Fragment>
            ))}
        </Fragment>
      ))}
    </>
  );
}

export default SummaryPdf;

export function getTitleWithoutNumber(title) {
  if (!title) return "";
  return title.replace(/^\d+/, "");
}

export function generateHrefPdf(title) {
  if (title.includes(":")) title = title.split(" :")[0]; // for "Méthode d'estimation : ..."

  if (!title) return "";
  return title
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replaceAll(/[^a-zA-Z0-9 ]/g, "")
    .replaceAll(" ", "-")
    .toLowerCase();
}
