import React, { useContext, useState, useEffect, useRef } from "react";
import "./CreateDeliverableModal.css";
import AppContext from "../../../../../context/AppContext";
import { set, useForm } from "react-hook-form";
import Input from "../../../../molecules/formComponents/input/Input";
import { defaultValidation } from "../../../../../utils/formValidation/FormValidation";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import { createDeliverableApi } from "../../../../../api/FolderApi";
import { getShortedString, toggleCollapseElement } from "../../../../../utils/Utils";
import Bracket from "../../../../atoms/icons/general/bracket/Bracket";
import { EstimationTabRowItem } from "../../../../../pages/account/account/tabs/accountsToolsTab/EstimationsTabComponents";
import Shop from "../../../../atoms/icons/header/shop/Shop";
import Factory from "../../../../atoms/icons/header/factory/Factory";
import Building from "../../../../atoms/icons/header/building/Building";
import LinkIcon from "../../../../atoms/icons/account/linkIcon/LinkIcon";

function CreateDeliverableModal({ folder, folderId, onLivrableCreated = () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);
  const [selectedEstimations, setSelectedEstimations] = useState([]);
  const [folderEstimations, setFolderEstimations] = useState([]);
  const [selectedFinancingPlans, setSelectedFinancingPlans] = useState([]);
  const [folderFinancingPlans, setFolderFinancingPlans] = useState([]);
  const [folderCreditSimulators, setFolderCreditSimulators] = useState([]);
  const [selectedCreditSimulators, setSelectedCreditSimulators] = useState([]);
  const [openedCategory, setOpenedCategory] = useState(0);

  const estimationsCategoryRef = useRef(null);
  const financingPlansCategoryRef = useRef(null);
  const creditSimulatorsCategoryRef = useRef(null);

  useEffect(() => {
    if (folder?.estimations) {
      setFolderEstimations(folder.estimations);
    }

    if (Array.isArray(folder?.financing_plans)) {
      setFolderFinancingPlans(folder.financing_plans);
    } else {
      setFolderFinancingPlans([]);
    }

    setFolderCreditSimulators(folder.credit_simulators);

    setTimeout(() => {
      if (folder?.estimations.length) toggleOpenedCategory(openedCategory, 1);
      else if (folder?.financing_plans.length) toggleOpenedCategory(openedCategory, 2);
      else if (folder?.credit_simulators.length) toggleOpenedCategory(openedCategory, 3);
    }, 100);
  }, [folder]);

  function toggleEstimationSelection(estimation) {
    if (selectedEstimations.includes(estimation)) {
      setSelectedEstimations(selectedEstimations.filter(e => e.id !== estimation.id));
    } else {
      setSelectedEstimations([...selectedEstimations, estimation]);
    }
  }

  function toggleFinancingPlanSelection(financingPlan) {
    if (selectedFinancingPlans.some(e => e.financing_plan_id === financingPlan.financing_plan_id)) {
      setSelectedFinancingPlans(
        selectedFinancingPlans.filter(e => e.financing_plan_id !== financingPlan.financing_plan_id),
      );
    } else {
      setSelectedFinancingPlans([...selectedFinancingPlans, financingPlan]);
    }
  }

  function toggleCreditSimulatorSelection(creditSimulator) {
    if (selectedCreditSimulators.some(e => e.credit_id === creditSimulator.credit_id)) {
      setSelectedCreditSimulators(selectedCreditSimulators.filter(e => e.credit_id !== creditSimulator.credit_id));
    } else {
      setSelectedCreditSimulators([...selectedCreditSimulators, creditSimulator]);
    }
  }

  function toggleOpenedCategory(openedCategory, category) {
    const categoryRef =
      category === 1
        ? estimationsCategoryRef
        : category === 2
          ? financingPlansCategoryRef
          : creditSimulatorsCategoryRef;

    const previewCategoryRef =
      openedCategory === 1
        ? estimationsCategoryRef
        : openedCategory === 2
          ? financingPlansCategoryRef
          : creditSimulatorsCategoryRef;

    if (previewCategoryRef.current) toggleCollapseElement(previewCategoryRef.current, false);

    if (openedCategory === category) return setOpenedCategory(0);

    setOpenedCategory(category);

    if (!categoryRef.current) return;

    if (openedCategory === 0) return toggleCollapseElement(categoryRef.current, true);

    return toggleCollapseElement(categoryRef.current, true);
  }

  async function onSubmit(data) {
    const estimationIds = selectedEstimations.map(estimation => ({ id: estimation.id }));
    const financingPlanIds = selectedFinancingPlans.map(financingPlan => ({ id: financingPlan.financing_plan_id }));
    const creditSimulatorIds = selectedCreditSimulators.map(creditSimulator => ({
      id: creditSimulator.credit_id,
    }));

    try {
      const res = await createDeliverableApi(
        data.deliverableName,
        folderId,
        estimationIds,
        financingPlanIds,
        creditSimulatorIds,
      );
      createNotification(<>Livrable créé avec succès</>);
      onLivrableCreated(res.data);
      setModalVisible(false);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la création du livrable</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='create-deliverable-modal'>
      <Input
        validation={defaultValidation}
        useForm={{ register, setValue }}
        error={errors.deliverableName?.message}
        name='deliverableName'
        label='Nom du livrable'
        placeholder='Acquéreur'
      />
      <div className='info-create-livrable mb-sm'>
        <p>
          Choisissez ci-dessous les estimations, plans de financement et simulations de crédit à importer dans votre
          livrable :
        </p>
      </div>
      <div className='create-deliverable-modal-list'>
        {folderEstimations.length ? (
          <div className='items-list-table estimations-table checkbox-list-container'>
            <h3 onClick={() => toggleOpenedCategory(openedCategory, 1)}>
              Importer des estimations
              <Bracket color='var(--dark-blue)' rotation={openedCategory === 1 ? "180deg" : "90deg"} width='15px' />
            </h3>
            <div className='items-table-body create-deliverable-table' ref={estimationsCategoryRef}>
              {folderEstimations.map((estimation, index) => (
                <div className='item-overview-container' key={estimation.id || index}>
                  <Checkbox
                    key={estimation.id || index}
                    name={`estimation-${estimation.id}`}
                    className='create-deliverable-checkbox'
                    label=''
                    onChange={() => toggleEstimationSelection(estimation)}
                    checked={selectedEstimations.includes(estimation)}
                  />
                  <div>{getShortedString(estimation.infos?.enseigne || estimation.infos?.nom, 28)}</div>
                  <div className={`estimation-type-${estimation.type?.description.split(" ")[0].toLowerCase()}`}>
                    {estimation.type?.id === 1 ? (
                      <Shop width='16px' color='var(--white)' className='mr-sm' />
                    ) : estimation.type?.id === 2 ? (
                      <Factory width='16px' color='var(--white)' className='mr-sm' />
                    ) : (
                      <Building width='16px' color='var(--white)' className='mr-sm' />
                    )}
                    {estimation.type?.description.split(" ")[0]}
                  </div>
                  <div>{getShortedString(`${estimation.infos?.rue} ${estimation.infos?.ville}`, 40)}</div>
                  <div>{estimation?.date_creation_estimation?.substring(0, 10)}</div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {folderFinancingPlans.length ? (
          <div className='items-list-table financing-plans-table checkbox-list-container'>
            <h3 onClick={() => toggleOpenedCategory(openedCategory, 2)}>
              Importer des plans de financement
              <Bracket color='var(--dark-blue)' rotation={openedCategory === 2 ? "180deg" : "90deg"} width='15px' />
            </h3>
            <div className='items-table-body create-deliverable-table' ref={financingPlansCategoryRef}>
              {folderFinancingPlans.map((plan, index) => (
                <div className='item-overview-container' key={plan.financing_plan_id || index}>
                  <Checkbox
                    name={`financingPlan-${plan.financing_plan_id}`}
                    className='create-deliverable-checkbox'
                    label=''
                    onChange={() => toggleFinancingPlanSelection(plan)}
                    checked={selectedFinancingPlans.some(e => e.financing_plan_id === plan.financing_plan_id)}
                  />
                  <div>{plan.financing_plan_name}</div>
                  <div className={`estimation-type-${plan.financing_plan_type === "fdc" ? "fonds" : "murs"}`}>
                    {plan.financing_plan_type === "fdc" ? (
                      <Shop width='16px' color='var(--white)' className='mr-sm' />
                    ) : (
                      <Building width='16px' color='var(--white)' className='mr-sm' />
                    )}
                    {plan.financing_plan_type === "fdc" ? "Fonds" : "Murs"}
                  </div>
                  <div />
                  <div>{plan?.date_creation?.split(" ")[0]}</div>
                  {plan.estimation_data ? (
                    <div className='financing-plan-link-icon-container'>
                      <div className='financing-plan-link-icon'>
                        <LinkIcon width='11px' />
                      </div>
                      <div className='financing-plan-link-tooltip'>
                        Ce plan de financement est lié à l'estimation {plan.estimation_data.estimation_name}
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {folderCreditSimulators.length ? (
          <div className='items-list-table credit-simulators-table checkbox-list-container'>
            <h3 onClick={() => toggleOpenedCategory(openedCategory, 3)}>
              Importer des simulations de prêt
              <Bracket color='var(--dark-blue)' rotation={openedCategory === 3 ? "180deg" : "90deg"} width='15px' />
            </h3>
            <div className='items-table-body create-deliverable-table' ref={creditSimulatorsCategoryRef}>
              {folderCreditSimulators.map((creditSimulator, index) => (
                <div key={creditSimulator.credit_id || index} className='item-overview-container'>
                  <Checkbox
                    name={`financingPlan-${creditSimulator.credit_id}`}
                    className='create-deliverable-checkbox'
                    label=''
                    onChange={() => toggleCreditSimulatorSelection(creditSimulator)}
                    checked={selectedCreditSimulators.some(e => e.credit_id === creditSimulator.credit_id)}
                  />
                  <div>{creditSimulator.credit_name}</div>
                  <div>{creditSimulator?.date_creation?.split(" ")[0]}</div>
                  <div />
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Créer le livrable
        </FilledButton>
      </div>
    </form>
  );
}

export default CreateDeliverableModal;
