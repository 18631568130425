import { Link } from "react-router-dom";
import Archive from "../../../../../components/atoms/icons/account/archive/Archive";
import Duplicate from "../../../../../components/atoms/icons/account/duplicate/Duplicate";
import Unarchive from "../../../../../components/atoms/icons/account/unarchive/Unarchive";
import Download from "../../../../../components/atoms/icons/general/download/Download";
import Pen from "../../../../../components/atoms/icons/account/pen/Pen";
import Eye from "../../../../../components/atoms/icons/general/eye/Eye";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../components/atoms/icons/general/add/Add";
import Bracket from "../../../../../components/atoms/icons/general/bracket/Bracket";
import ReviewButton from "../../../../../components/molecules/buttons/reviewButton/ReviewButton";
import Check from "../../../../../components/atoms/icons/general/check/Check";
import Shop from "../../../../../components/atoms/icons/header/shop/Shop";
import Factory from "../../../../../components/atoms/icons/header/factory/Factory";
import Building from "../../../../../components/atoms/icons/header/building/Building";
import { getShortedString } from "../../../../../utils/Utils";
import ProgressCircle from "../../../../../components/atoms/progressCircle/ProgressCircle";
import Copy from "../../../../../components/atoms/icons/account/copy/Copy";
import Trash from "../../../../../components/atoms/icons/general/trash/Trash";

export const EstimationsTabFilters = () => (
  <>
    <div>Ref</div>
    <div>Enseigne</div>
    <div>Type</div>
    <div>Adresse</div>
    <div>Date</div>
    <div className='centered'></div>
    <div>Actions</div>
  </>
);

export const EstimationsTabDropdownMenu = ({
  openedItemDropdown,
  downloadItemPdf,
  showDuplicateItemModal,
  archiveItem,
  removeFromFolder,
  removeFromDeliverable,
}) => (
  <>
    <li>
      <Link to={`/synthese/${openedItemDropdown?.item?.id}`}>
        <Pen width='18px' />
        Modifier
      </Link>
    </li>
    {openedItemDropdown?.item?.validated ? (
      <>
        {downloadItemPdf && (
          <li>
            <button onClick={() => downloadItemPdf(openedItemDropdown?.item?.id)}>
              <Download color='var(--dark-blue)' width='18px' />
              Télécharger
            </button>
          </li>
        )}
        <li>
          <Link to={`/pdf/${openedItemDropdown?.item?.id}`} target='_blank'>
            <Eye color='var(--dark-blue)' width='18px' />
            Prévisualiser
          </Link>
        </li>
        {showDuplicateItemModal && (
          <li>
            <button onClick={() => showDuplicateItemModal(openedItemDropdown?.item?.id)}>
              <Duplicate width='18px' />
              Dupliquer
            </button>
          </li>
        )}
      </>
    ) : (
      <>
        {downloadItemPdf && (
          <li>
            <button className='disabled-button-dropdown-item tooltip-item' data-tooltip='Estimation non terminée'>
              <Download width='18px' color='var(--dark-blue)' />
              Télécharger
            </button>
          </li>
        )}
        {showDuplicateItemModal && (
          <li>
            <button className='disabled-button-dropdown-item tooltip-item' data-tooltip='Estimation non terminée'>
              <Duplicate width='18px' />
              Dupliquer
            </button>
          </li>
        )}
      </>
    )}
    {removeFromFolder && (
      <li>
        <button onClick={() => removeFromFolder(openedItemDropdown?.item?.id)}>
          <Trash width='18px' />
          Retirer du dossier
        </button>
      </li>
    )}
    {removeFromDeliverable && (
      <li>
        <button onClick={() => removeFromDeliverable(openedItemDropdown?.item?.id)}>
          <Trash width='18px' />
          Retirer du livrable
        </button>
      </li>
    )}
    {archiveItem &&
      (openedItemDropdown?.item?.archived ? (
        <li>
          <button onClick={() => archiveItem(openedItemDropdown?.item?.id, false)}>
            <Unarchive width='18px' />
            Désarchiver
          </button>
        </li>
      ) : (
        <li>
          <button onClick={() => archiveItem(openedItemDropdown?.item?.id, true)}>
            <Archive width='18px' />
            Archiver
          </button>
        </li>
      ))}
  </>
);

export const EstimationTabCreateButton = ({ width }) => (
  <FilledButton to='/debut-parcours' className='mid-page-button items-button'>
    <Add width='30px' /> Démarrer une nouvelle estimation
  </FilledButton>
);

export const EstimationTabReviewButton = ({ text, link }) => (
  <ReviewButton modalTitle='Votre avis sur nos estimations' modalText='une estimation' link={link}>
    {text}
  </ReviewButton>
);

export const EstimationTabRowItem = ({
  index,
  item,
  date,
  copyIdToClipboard = () => {},
  toggleOpenedItemDropdown,
  openedItemDropdown,
  showStatus,
}) =>
  item && (
    <Link className='item-overview-container' to={`/synthese/${item.id}`} key={index}>
      <div className='item-copy' onClick={e => copyIdToClipboard(e, item.credit_id)}>
        <Copy width='11px' color='var(--white)' onClick={e => copyIdToClipboard(e, item.id)} />
      </div>
      <div>{getShortedString(item.infos?.enseigne || item.infos?.nom, 27)}</div>
      <div className={`estimation-type-${item.type?.description.split(" ")[0].toLowerCase()}`}>
        {item.type?.id === 1 ? (
          <Shop width='16px' color='var(--white)' className='mr-sm' />
        ) : item.type?.id === 2 ? (
          <Factory width='16px' color='var(--white)' className='mr-sm' />
        ) : (
          <Building width='16px' color='var(--white)' className='mr-sm' />
        )}
        {item.type?.description.split(" ")[0]}
      </div>
      <div>{getShortedString(`${item.infos?.rue} ${item.infos?.ville}`, 23)}</div>
      <div>{date}</div>
      {item.steps_status?.completed_steps === item.steps_status?.total_steps ? (
        <div className={"estimation-validated" + (showStatus ? "" : "hidden")}>
          {showStatus && <Check width='11px' color='var(--white)' />}
        </div>
      ) : (
        <ProgressCircle
          className={"estimation-not-validated" + (showStatus ? "" : "hidden")}
          totalSteps={item.steps_status?.total_steps}
          currentStep={item.steps_status?.completed_steps}
        />
      )}
      {toggleOpenedItemDropdown && (
        <div className='item-filters-buttons' onClick={e => toggleOpenedItemDropdown(e, index)}>
          <Bracket
            rotation={openedItemDropdown?.key === index ? "0" : "180deg"}
            width='14px'
            color='var(--white)'
            className='dropdown-icon-item'
          />
        </div>
      )}
    </Link>
  );

export const EstimationResponsiveThumbnail = ({
  item,
  date,
  archiveItem = () => {},
  downloadItemPdf = () => {},
  showDuplicateItemModal = () => {},
}) =>
  item && (
    <div className='item-thumbnail-container'>
      <div className='text-center'>Enseigne</div>
      <div className='item-thumbnail'>
        <div className='item-enseigne'>
          {item.infos?.enseigne ?? ""}
          {item.infos?.nom}
        </div>
        <div className='item-infos'>
          <p className='label'>Type</p>
          <p>{item.type?.description}</p>
          <p className='label'>Adresse</p>
          <p>{`${item.infos?.rue} ${item.infos?.ville}`}</p>
          <p className='label'>Date</p>
          <p>{date}</p>
        </div>
        {item.validated ? (
          <div className='estimation-thumbnail-previsu-button'>
            <FilledButton to={`/pdf/${item.id}`} target='_blank'>
              <Eye width='30px' />
            </FilledButton>
            Prévisualiser
          </div>
        ) : (
          <div className='estimation-thumbnail-previsu-button'>
            <div className='fake-button'>
              <Eye width='30px' color='var(--dark-blue)' />
              <div>Estimation non terminée</div>
            </div>
            Prévisualiser
          </div>
        )}
        <div className='item-buttons'>
          <Link to={`/synthese/${item.id}`}>
            <FilledButton color='var(--dark-blue)' bgColor='#E6CC83' hoverColor='#E6CC83'>
              <Pen width='30px' />
            </FilledButton>
            Modifier
          </Link>
          {item.validated ? (
            <>
              <div onClick={() => downloadItemPdf(item.id)} target='_blank'>
                <div>
                  <FilledButton bgColor='#B8C7FF' hoverColor='#B8C7FF'>
                    <Download color='var(--dark-blue)' width='30px' />
                  </FilledButton>
                  Télécharger
                </div>
              </div>
              <div onClick={() => showDuplicateItemModal(item.id, item?.infos?.enseigne)}>
                <FilledButton bgColor='#E3E3E3' hoverColor='#E3E3E3'>
                  <Duplicate width='30px' />
                </FilledButton>
                Dupliquer
              </div>
            </>
          ) : (
            <>
              <div>
                <div className='fake-button'>
                  <Download width='30px' color='var(--dark-blue)' />
                  <div>Estimation non terminée</div>
                </div>
                Télécharger
              </div>
              <div>
                <div className='fake-button'>
                  <Duplicate width='30px' />
                  <div>Estimation non terminée</div>
                </div>
                Dupliquer
              </div>
            </>
          )}
          {item.archived ? (
            <>
              <div>
                <FilledButton bgColor='#FDB7A9' hoverColor='#FDB7A9' onClick={() => archiveItem(item.id, false)}>
                  <Unarchive width='30px' />
                </FilledButton>
                Désarchiver
              </div>
            </>
          ) : (
            <div>
              <FilledButton bgColor='#FDB7A9' hoverColor='#FDB7A9' onClick={() => archiveItem(item.id, true)}>
                <Archive width='30px' />
              </FilledButton>
              Archiver
            </div>
          )}
        </div>
      </div>
    </div>
  );
