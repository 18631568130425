import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ParcoursContext } from "../../../../../context/ParcoursContext.js";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput.js";
import { formatObjectForPosting, removeSpaces } from "../../../../../utils/Utils.js";
import Tuto from "../../../../atoms/icons/general/tuto/Tuto.js";
import AppContext from "../../../../../context/AppContext.js";

function BilanComptable({ id, index }) {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
    clearErrors,
    unregister,
    watch,
    reset,
    setFocus,
    formState: { errors },
  } = useForm();
  const {
    typesCommerce,
    moveToNextStep,
    getStepToGo,
    setGoToStep,
    CAInputs,
    resetFormsRef,
    handleTabs,
    step,
    setStep,
    submitFormsFdc,
    postValues,
    fillInputsFromEstimationValues,
    estimationValues,
    parcoursType,
  } = useContext(ParcoursContext);
  const { showTooltip } = useContext(AppContext);

  const inputForm = { watch, register, setValue, unregister };

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
    resetFormsRef.current[index] = () => reset();
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      ["bilan_comptable", "ca", "charges", "materiel_exploitation"],
      setValue,
      estimationValues,
    );
  }, [estimationValues, CAInputs]);

  async function formSubmit(values) {
    if (!checkCANotNull(true)) return;

    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  function checkCANotNull(shouldFocusInput) {
    const ca = { ...getValues("ca") };
    delete ca.total_commissions_nettes;

    for (const value of Object.values(ca)) {
      if (removeSpaces(value) > 0) {
        clearErrors("ca");
        return true;
      }
    }

    Object.keys(ca).forEach(key => {
      setError("ca." + key, {
        type: "custom",
        message: "Vous devez saisir un montant positif pour obtenir un résultat d’estimation",
      });
    });

    if (shouldFocusInput) setFocus("ca." + Object.keys(ca)[0], { shouldSelect: true });

    return false;
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
      <p className='text-sky-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      {parcoursType === 2 ? ( // tds only
        <>
          <fieldset className='borderless-fieldset'>
            <h2>bilan comptable</h2>
            <NumberInput // visible tds
              useForm={inputForm}
              label={
                <>
                  <div>
                    immobilisations financières <span className='text-sky-blue'>(du dernier bilan connu)</span>
                    <Tuto className='ml-sm' onClick={() => showTooltip("step_4_immobilisations_financieres.webp")} />
                  </div>
                </>
              }
              name='bilan_comptable.immobilisations_financieres'
              error={errors?.bilan_comptable?.immobilisations_financieres?.message}
              icon='euro'
            />
            <NumberInput // visible tds
              useForm={inputForm}
              label={
                <>
                  <div>
                    actif circulant <span className='text-sky-blue'>(du dernier bilan connu)</span>
                    <Tuto className='ml-sm' onClick={() => showTooltip("step_4_actif_circulant.webp")} />
                  </div>
                </>
              }
              name='bilan_comptable.actif_circulant'
              error={errors?.bilan_comptable?.actif_circulant?.message}
              icon='euro'
            />
            <NumberInput // visible tds
              useForm={inputForm}
              label={
                <>
                  <div>
                    comptes courants d'associés <span className='text-sky-blue'>(du dernier bilan connu)</span>
                    <Tuto className='ml-sm' onClick={() => showTooltip("step_4_comptes_courants_associes.webp")} />
                  </div>
                </>
              }
              name='bilan_comptable.comptes_courants_associes'
              error={errors?.bilan_comptable?.comptes_courants_associes?.message}
              icon='euro'
            />
            <NumberInput // visible tds
              useForm={inputForm}
              label={
                <>
                  <div>
                    total des dettes (dont comptes courants d'associés)
                    <span className='text-sky-blue'> (du dernier bilan connu)</span>
                    <Tuto className='ml-sm' onClick={() => showTooltip("step_4_total_dettes.webp")} />
                  </div>
                </>
              }
              name='bilan_comptable.total_dettes'
              error={errors?.bilan_comptable?.total_dettes?.message}
              icon='euro'
            />
          </fieldset>
          <fieldset className='borderless-fieldset'>
            <h2>matériel d'exploitation</h2>
            <NumberInput // visible tds
              useForm={inputForm}
              label={
                <>
                  <div>
                    valeur de marché <span className='text-sky-blue'>(du dernier bilan connu)</span>
                    <Tuto className='ml-sm' onClick={() => showTooltip("step_4_valeur_marche.webp")} />
                  </div>
                </>
              }
              name='materiel_exploitation.valeur_materiel_exploitation'
              error={errors?.materiel_exploitation?.valeur_materiel_exploitation?.message}
              icon='euro'
            />
          </fieldset>
        </>
      ) : null}
      <fieldset className='borderless-fieldset'>
        <h2>chiffre d’affaires hors taxes</h2>
        {CAInputs.map(input => (
          <NumberInput
            key={input.id}
            useForm={inputForm}
            name={"ca." + input.input_name}
            icon='euro'
            error={errors?.ca?.[input.input_name]?.message}
            onChange={() => checkCANotNull()}
            label={
              <>
                {input.label}
                {input.tuto && <Tuto onClick={() => showTooltip(input.tuto)} />}
              </>
            }
          />
        ))}
        <NumberInput
          useForm={inputForm}
          visible={typesCommerce.includes(2)}
          label={
            <>
              total des commissions nettes
              <Tuto
                onClick={() =>
                  showTooltip(
                    typesCommerce.includes(1)
                      ? "step_4_total_commissions_nettes_2.webp"
                      : "step_4_total_commissions_nettes_1.webp",
                  )
                }
              />
            </>
          }
          name='ca.total_commissions_nettes'
          error={errors?.ca?.total_commissions_nettes?.message}
          type='number'
          icon='euro'
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>coût matières et charges externes</h2>
        <NumberInput
          useForm={inputForm}
          label={
            <>
              coût d’achat marchandises et matières premières
              <Tuto onClick={() => showTooltip("step_4_achat_marchandise.webp")} />
            </>
          }
          name='charges.achat_marchandise'
          error={errors?.charges?.achat_marchandise?.message}
          icon='euro'
        />
        <NumberInput
          label={
            <>
              total des autres achats et charges externes
              <Tuto onClick={() => showTooltip("step_4_total_charges_externes.webp")} />
            </>
          }
          useForm={inputForm}
          name='charges.total_charges_externes'
          error={errors?.charges?.total_charges_externes?.message}
          icon='euro'
          {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
        />
      </fieldset>
    </form>
  );
}

export default BilanComptable;
