import React, { useContext, useState, useRef, useEffect } from "react";
import "./FolderFinancingPlan.css";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../../../components/atoms/icons/general/add/Add";
import Download from "../../../../../../../components/atoms/icons/general/download/Download";
import { useWindowSize } from "@uidotdev/usehooks";
import Tuto from "../../../../../../../components/atoms/icons/general/tuto/Tuto";
import Rapport from "../../../../../../../components/atoms/icons/header/rapport/Rapport";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../../../../../context/AppContext";
import ImportFinancingPlansFolderModal from "../../../../../../../components/forms/modals/account/importFinancingPlansFolderModal/ImportFinancingPlansFolderModal";
import {
  deleteFinancingPlanFromFolderApi,
  deleteAllFinancingPlansFromFolderApi,
} from "../../../../../../../api/FolderApi";
import UnfilledButton from "../../../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import Pen from "../../../../../../../components/atoms/icons/account/pen/Pen";
import Trash from "../../../../../../../components/atoms/icons/general/trash/Trash";
import Bracket from "../../../../../../../components/atoms/icons/general/bracket/Bracket";
import { useAuth } from "../../../../../../../context/AuthContext";
import { Link } from "react-router-dom";
import { downloadFinancingPlanPdfApi } from "../../../../../../../api/DownloadApi";
import { copyToClipboard, createDownloadLink } from "../../../../../../../utils/Utils";
import LinkIcon from "../../../../../../../components/atoms/icons/account/linkIcon/LinkIcon";
import DuplicateFinancingPlanModal from "../../../../../../../components/forms/modals/account/duplicateFinancingPlanModal/DuplicateFinancingPlanModal";
import {
  FinancingPlansTabDropdownMenu,
  FinancingPlansTabFilters,
  FinancingPlanTabRowItem,
} from "../../../accountsToolsTab/FinancingPlansTabComponents";

function FolderFinancingPlan({ folder, triggerFolderUpdate }) {
  const { setModalContent, setModalVisible, createNotification, setIsDownloadNotificationLoading } =
    useContext(AppContext);
  const [openedItemDropdown, setOpenedItemDropdown] = useState(false);
  const [itemsContainerScroll, setItemsContainerScroll] = useState(0);
  const itemsContainerRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const { width } = useWindowSize();
  const { getUuid } = useAuth();

  useEffect(() => {
    document.addEventListener("click", handleCloseDropdownMenu);

    return () => {
      document.removeEventListener("click", handleCloseDropdownMenu);
    };
  }, [dropdownMenuRef.current]);

  useEffect(() => {
    if (!itemsContainerRef.current) return;

    onItemsContainerScroll();

    itemsContainerRef.current?.addEventListener("scroll", onItemsContainerScroll);

    return () => {
      itemsContainerRef.current?.removeEventListener("scroll", onItemsContainerScroll);
    };
  }, [openedItemDropdown]);

  function handleCloseDropdownMenu() {
    if (!dropdownMenuRef.current) return;

    setOpenedItemDropdown(false);
  }

  function onItemsContainerScroll() {
    if (!openedItemDropdown) return setItemsContainerScroll(itemsContainerRef.current.scrollTop);

    const elementOffsetBottom =
      itemsContainerRef.current.children[openedItemDropdown.key]?.offsetTop ??
      0 + itemsContainerRef.current.children[openedItemDropdown.key]?.offsetHeight ??
      0;

    const containerOffsetBottom = itemsContainerRef.current.scrollTop + itemsContainerRef.current.offsetHeight;
    let dropdownMenuHeight = 20;
    for (const child of dropdownMenuRef.current.children) dropdownMenuHeight += child.offsetHeight + 10;

    if (elementOffsetBottom + dropdownMenuHeight + 25 > containerOffsetBottom)
      setItemsContainerScroll(itemsContainerRef.current.scrollTop + dropdownMenuHeight + 15);
    else setItemsContainerScroll(itemsContainerRef.current.scrollTop);

    if (
      (openedItemDropdown && elementOffsetBottom < itemsContainerRef.current.scrollTop) ||
      elementOffsetBottom > containerOffsetBottom
    )
      setOpenedItemDropdown(false);
  }

  function toggleOpenedItemDropdown(e, key) {
    e.preventDefault();

    if (!dropdownMenuRef.current)
      return createNotification(<>Une erreur est survenue. Veuillez réessayer.</>, "var(--red)", "var(--dark-blue)");

    const tmp = openedItemDropdown;
    const top = itemsContainerRef.current.children[key].offsetTop + 60;

    if (openedItemDropdown) setOpenedItemDropdown(false);

    setTimeout(() => {
      if (tmp.key !== key)
        setOpenedItemDropdown({
          item: folder.financing_plans[key],
          key,
          top,
        });
    }, 10);
  }

  function openImportFinancingPlanModal() {
    setModalContent({
      title: "Importer des plans de financement",
      content: (
        <ImportFinancingPlansFolderModal
          folderId={folder.data.folder_id}
          getUuid={getUuid}
          folder={folder}
          onFinancingPlanAdded={triggerFolderUpdate}
        />
      ),
      className: "big-modal",
    });
    setModalVisible(true);
  }

  function handleDeleteFinancingPlan(financingPlanId) {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <div>
          <p>
            Êtes-vous sûr de vouloir retirer ce plan de financement de votre dossier ?<br />
            Cette action est irréversible.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                confirmDeleteFinancingPlan(financingPlanId);
              }}>
              Retirer
            </FilledButton>
          </div>
        </div>
      ),
    });
    setModalVisible(true);
  }

  async function confirmDeleteFinancingPlan(financingPlanId) {
    try {
      await deleteFinancingPlanFromFolderApi(folder.data.folder_id, financingPlanId, true);

      triggerFolderUpdate();

      createNotification(<>Le plan de financement a été retiré avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait du plan de financement</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function handleDeleteAllFinancingPlans() {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <div>
          <p>
            Êtes-vous sûr de vouloir retirer{" "}
            {folder.financing_plans.length > 1
              ? "tous les plans de financement du dossier"
              : "le plan de financement du dossier"}{" "}
            ?
            <br /> Cette action est irréversible et retirera également les plans de financement des livrables associés.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                confirmDeleteAllFinancingPlans();
              }}>
              Retirer
            </FilledButton>
          </div>
        </div>
      ),
    });
    setModalVisible(true);
  }

  async function confirmDeleteAllFinancingPlans() {
    try {
      await deleteAllFinancingPlansFromFolderApi(folder.data.folder_id, true);

      triggerFolderUpdate();

      createNotification(<>Tous les plans de financement ont été retirés avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait des plans de financement</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function downloadFinancingPlan(planId) {
    try {
      setIsDownloadNotificationLoading(true);

      const res = await downloadFinancingPlanPdfApi(planId);

      const link = createDownloadLink(res.data);
      link.click();
      link.remove();

      createNotification(<>Le plan de financement a été téléchargé avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du téléchargement du plan de financement. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setIsDownloadNotificationLoading(false);
    }
  }

  function getDuplicateFinancingPlanName(financingPlanId) {
    const financingPlanName = folder.financing_plans.find(
      financingPlan => financingPlan.financing_plan_id === financingPlanId,
    ).financing_plan_name;
    const regex = /#\d+$/;

    if (regex.test(financingPlanName)) {
      const match = financingPlanName.match(/\d+$/);

      return financingPlanName.replace(regex, `#${parseInt(match[0]) + 1}`);
    } else {
      return `${financingPlanName} #1`;
    }
  }

  function showDuplicateFinancingPlanModal(financingPlanId) {
    setModalContent({
      title: "Dupliquer le plan de financement",
      content: (
        <DuplicateFinancingPlanModal
          defaultValue={getDuplicateFinancingPlanName(financingPlanId)}
          fetchFinancingPlansFunction={() => {}}
          financingPlanId={financingPlanId}
        />
      ),
    });

    setModalVisible(true);
  }

  function copyIdToClipboard(e, id) {
    e.preventDefault();
    e.stopPropagation();

    copyToClipboard(id, <>La référence a été copiée dans votre presse-papier avec succès.</>, createNotification);
  }

  return (
    <div>
      <div className='tab-content-header'>
        <h2>
          <Rapport />
          Plan de financement
        </h2>
        <p>Retrouvez ici tous les plans de financement liés à votre dossier.</p>
      </div>
      <div className='folder-plan-buttons'>
        <FilledButton to='/plan-financement'>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer un plan de financement
        </FilledButton>
        <FilledButton onClick={openImportFinancingPlanModal}>
          <Download width={width > 576 ? "30px" : "20px"} /> Importer un plan de financement
        </FilledButton>
      </div>
      <div className='folder-table-estimations items-table financing-plans-table'>
        <div className='items-filters'>
          <FinancingPlansTabFilters />
        </div>
        <ul
          ref={dropdownMenuRef}
          className={`dropdown-menu-item ${openedItemDropdown ? "show" : ""}`}
          style={{ "--top": openedItemDropdown?.top - itemsContainerScroll + "px" }}>
          <FinancingPlansTabDropdownMenu
            openedItemDropdown={openedItemDropdown}
            downloadItemPdf={downloadFinancingPlan}
            showDuplicateItemModal={showDuplicateFinancingPlanModal}
            removeFromFolder={handleDeleteFinancingPlan}
          />
        </ul>
        <div className='items-table-body' ref={itemsContainerRef}>
          {folder?.financing_plans?.length ? (
            folder.financing_plans.map((plan, index) => (
              <FinancingPlanTabRowItem
                key={plan.financing_plan_id}
                index={index}
                item={plan}
                copyIdToClipboard={copyIdToClipboard}
                date={plan.date_creation?.substring(0, 10)}
                toggleOpenedItemDropdown={toggleOpenedItemDropdown}
                openedItemDropdown={openedItemDropdown}
              />
            ))
          ) : (
            <div className='info-block-plan'>
              <p>Aucun plan de financement dans ce dossier</p>
            </div>
          )}
        </div>
        <div className='folder-table-plan-footer mt-sm'>
          {folder.financing_plans.length > 0 && (
            <UnfilledButton bgColor='var(--dark-red)' onClick={handleDeleteAllFinancingPlans}>
              {folder.financing_plans.length > 1
                ? "Retirer tous les plans de financement du dossier"
                : "Retirer le plan de financement du dossier"}
            </UnfilledButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default FolderFinancingPlan;
