import React from "react";
import Header from "../../../components/organisms/header/Header";
import Footer from "../../../components/organisms/footer/Footer";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../components/molecules/banner/Banner";
import { Link } from "react-router-dom";
import TextHr from "../../../components/molecules/textHr/TextHr";

function Cgu() {
  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            { name: "Accueil", route: "/" },
            { name: "Liens légaux: Conditions générales d'utilisation", route: "/cgu" },
          ]}
        />
        <Banner title="Conditions générales d'utilisation" subtitle='Applicables au 17 décembre 2024' />
        <div className='big-text-container mt-xl'>
          <p className='outfit-bold text-underline'>IMPORTANT&nbsp;:</p>
          <p className='outfit-bold'>
            L'accès au site Internet{" "}
            <Link to='https://www.estimermoncommerce.fr' className='link'>
              www.estimermoncommerce.fr
            </Link>
            , sa consultation et son utilisation sont subordonnés à l'acceptation sans réserve des présentes conditions
            générales d'utilisation (ci-après « les CGU ») par tout internaute.{" "}
          </p>
          <p className='outfit-bold'>
            En naviguant sur le site{" "}
            <Link to='https://www.estimermoncommerce.fr' className='link'>
              www.estimermoncommerce.fr
            </Link>
            , vous reconnaissez avoir pris connaissance et avoir accepté pleinement et sans réserve les présentes CGU.
          </p>
          <p className='outfit-bold'>
            La Sas GALAXICO attire votre attention sur l'importance de les lire attentivement.
          </p>
          <TextHr className='my-xl'>Article 1. Présentation du Site</TextHr>
          <p>
            Le site Internet accessible à l’adresse{" "}
            <Link to='https://www.estimermoncommerce.fr' className='link'>
              www.estimermoncommerce.fr
            </Link>{" "}
            (ci-après « le Site ») est édité et exploité par la société GALAXICO, SAS au capital social de 10 000 €,
            immatriculée au RCS de PARIS sous le numéro 878 387 885, ayant son siège social situé 41 rue de Cronstadt
            75015 PARIS (FRANCE), et dont les coordonnées sont les suivantes&nbsp;:
          </p>
          <ul className='dots-list'>
            <li>
              Téléphone&nbsp;: 06 63 84 52 56, du lundi au vendredi, hors jours fériés et congés annuels, de 10h à 12h
              et de 14h à 17h&nbsp;;
            </li>
            <li>
              Email&nbsp;:{" "}
              <Link className='link' to='mailto:contact@estimermoncommerce.fr'>
                contact@estimermoncommerce.fr
              </Link>
              .
            </li>
          </ul>
          <p>
            La Sas GALAXICO met à votre disposition sur le Site un service d’estimation de fonds de commerce, de titres
            de société ou de murs commerciaux, ainsi que des informations, des conseils, des actualités et des outils
            pratiques relatifs à l’estimation, l’optimisation et la transmission de commerce et d’entreprise.
          </p>
          <p>
            Le Site vous permet également d’être mis en relation avec la Sas GALAXICO pour qu’elle vous recontacte, de
            vous inscrire à sa newsletter et de créer et accéder à votre compte client (ci-après le « Compte »).
          </p>
          <TextHr className='my-xl'>Article 2. Objet</TextHr>
          <p>
            Les CGU ont pour objet de déterminer les conditions d’utilisation du Site par tout utilisateur, personne
            physique, quel qu’il soit, âgé d’au moins 18 ans (« vous »).
          </p>
          <p>
            Elles visent notamment à définir les conditions dans lesquelles vous pouvez accéder et naviguer sur le Site
            et utiliser les différentes fonctionnalités qui y sont proposées.
          </p>
          <TextHr className='my-xl'>Article 3. Acceptation des CGU</TextHr>
          <p>
            En accédant ou en utilisant le Site, vous reconnaissez avoir pris connaissance de l'ensemble des CGU et les
            accepter expressément sans réserve ni modification de quelque nature que ce soit.
          </p>
          <p>
            Si vous n’approuvez pas l’une ou l’autre des stipulations des CGU ou si vous n'avez pas la capacité
            juridique de les accepter, vous n’êtes pas autorisé à accéder au Site et à l’utiliser.
          </p>
          <p>
            Vous êtes également invité à consulter la{" "}
            <Link className='link' to='/politique-confidentialite'>
              Politique de confidentialité du Site
            </Link>
            .
          </p>
          <TextHr className='my-xl'>Article 4. Accès au site</TextHr>
          <p>
            Pour accéder au Site, vous devez disposer d’un accès à Internet, dont le coût est à votre charge, et
            reconnaissez que&nbsp;:
          </p>
          <ul className='dots-list'>
            <li>
              Il vous appartient de prendre toute mesure que vous jugerez appropriée pour assurer la sécurité de votre
              terminal de navigation&nbsp;;
            </li>
            <li>
              Tout terminal de navigation connecté au Site est et reste sous votre entière responsabilité, la
              responsabilité de la Sas GALAXICO ne pouvant pas être recherchée pour tout dommage direct ou indirect qui
              pourrait survenir du fait de votre connexion au Site.
            </li>
          </ul>
          <p>L’accès au Site est gratuit, hors coût des télécommunications et d’accès au réseau Internet.</p>
          <p>
            La souscription aux offres de service proposées par la Sas GALAXICO sur le Site est soumise aux{" "}
            <Link className='link' to='/cgv'>
              Conditions Générales de Vente
            </Link>{" "}
            du Site.
          </p>
          <TextHr className='my-xl'>Article 5. Fonctionnalités du Site</TextHr>
          <p>
            Le Site est ouvert à tout internaute remplissant les conditions prévues à l’article « Objet » ci-dessus,
            sous réserve de disposer d’une connexion au réseau Internet.
          </p>
          <p>Le Site vous permet notamment d’avoir accès aux fonctionnalités suivantes&nbsp;:</p>
          <h3 className='h2-uppercase my-md'>1 - Formulaire de contact</h3>
          <p>
            Vous pouvez remplir un formulaire de contact, en renseignant votre nom, votre prénom, votre adresse e-mail,
            l’objet du message et un message afin d’être contacté par la Sas GALAXICO.
          </p>
          <h3 className='h2-uppercase my-md'>2 - Inscription à la newsletter</h3>
          <p>
            Vous pouvez vous abonner à la newsletter de la Sas GALAXICO en renseignant votre adresse e-mail, afin de
            recevoir des informations concernant l’actualité, les activités et les offres commerciales de la Sas
            GALAXICO.
          </p>
          <p>
            Vous pourrez à tout moment vous désinscrire de cette newsletter en cliquant sur le lien inséré à cet effet
            dans les e-mails de newsletter qui vous sont adressés par la Sas GALAXICO.
          </p>
          <h3 className='h2-uppercase my-md'>3 - Création d’un Compte</h3>
          <p>
            Vous pouvez créer un Compte dans les conditions prévues à l’article « Création d’un compte » ci-dessous.
          </p>
          <h3 className='h2-uppercase my-md'>4 - Souscription aux offres de service</h3>
          <p>
            Vous pouvez consulter les offres de service proposés à la vente sur le Site par la Sas GALAXICO et les
            commander après avoir créé un Compte et vous y être identifié.
          </p>
          <h3 className='h2-uppercase my-md'>5 - Consultation de documentation</h3>
          <p>
            Vous pouvez consulter la documentation fournie par la Sas GALAXICO sur le Site, à savoir des conseils, et
            des outils pratiques relatifs à l’estimation de commerce et d’entreprise, ainsi qu’à l’univers de la
            transmission.
          </p>
          <p>
            Les sections et fonctionnalités suivantes du Site sont en accès libre, sous réserve, pour certaines d’entre
            elles, que vous ayez préalablement créé et validé votre Compte&nbsp;:
          </p>
          <ul className='dots-list'>
            <li>Méthode et philosophie</li>
            <li>Exemples de rapports</li>
            <li>Activités estimables</li>
            <li>Lexique commercial</li>
            <li>FAQ</li>
            <li>Conseils de pros</li>
            <li>Nos guides pour agents immos</li>
            <li>Simulateur de crédit</li>
            <li>Plan de financement (uniquement la version accessible par le menu Simulateurs)</li>
            <li>Calculateur de cotisations (uniquement la version accessible par le menu Simulateurs)</li>
          </ul>
          <h3 className='h2-uppercase my-md'>6 - Demande de démonstration de l’outil</h3>
          <p>
            Vous pouvez demander une démonstration en ligne en cliquant sur la touche « demander une démo ». Une vidéo
            de démonstration du Site, à télécharger, vous sera envoyée par email.
          </p>
          <TextHr className='my-xl'>Article 6. Compte</TextHr>
          <h3 className='h2-uppercase my-md'>1 - Règles générales</h3>
          <p>
            Votre Compte vous est strictement personnel&nbsp;: en conséquence, vous vous interdisez de céder ou
            transférer, de quelque manière que ce soit et à quelque titre que ce soit, le droit d’accès à votre Compte à
            un tiers.
          </p>
          <h3 className='h2-uppercase my-md'>2 - Création du compte</h3>
          <p>
            Vous pouvez procéder à la création d’un Compte depuis la rubrique « Connexion », puis en cliquant sur «
            Inscription ».
          </p>
          <p>
            Vous pouvez également procéder à la création d’un Compte à la fin du parcours d’estimation en ligne, en
            renseignant les données du formulaire « Inscription ».
          </p>
          <p>La création d’un Compte est obligatoire pour pouvoir souscrire à une offre de service payante.</p>
          <p>Pour créer votre Compte, vous devez renseigner les informations suivantes&nbsp;:</p>
          <ul className='dots-list'>
            <li>Votre nom&nbsp;;</li>
            <li>Votre prénom&nbsp;;</li>
            <li>La raison sociale de votre structure&nbsp;;</li>
            <li>Votre adresse postale&nbsp;;</li>
            <li>Votre adresse e-mail&nbsp;;</li>
            <li>Votre téléphone&nbsp;;</li>
            <li>Votre nature d’utilisateur&nbsp;;</li>
            <li>Un mot de passe de votre choix.</li>
          </ul>
          <p>
            En procédant à la création de votre Compte, vous certifiez que vous êtes une personne physique âgée de plus
            de 18 ans, bénéficiant de la capacité juridique nécessaire.
          </p>
          <p>De plus, vous vous engagez à fournir des informations exactes, complètes et à jour.</p>
          <p>Vous pouvez, lors de la création de votre compte, vous inscrire à la newsletter.</p>
          <h3 className='h2-uppercase my-md'>3 - Mot de passe</h3>
          <p>
            Le mot de passe de votre Compte étant strictement personnel et confidentiel, il vous appartient de le
            conserver précieusement, sans le communiquer à quiconque.
          </p>
          <p>
            Dès lors que vous êtes seul responsable de la conservation et de l’utilisation de votre mot de passe, vous
            répondez seul de toutes les conséquences d’une utilisation par vous ou par un tiers, frauduleuse ou non, de
            votre Compte et vous engagez à signaler dans les meilleurs délais à la Sas GALAXICO toute utilisation
            frauduleuse de votre Compte dont vous auriez connaissance.
          </p>
          <h3 className='h2-uppercase my-md'>4 - Fonctionnement</h3>
          <p>
            Lors de chaque connexion à votre Compte, vous reconnaissez que la saisie successive de votre adresse e-mail
            et de votre mot de passe vaut identification de votre part.
          </p>
          <p>
            Votre Compte vous permet, depuis la rubrique{" "}
            <Link className='link' to='/mon-compte/mes-outils'>
              Mon Compte
            </Link>
            , d’accéder aux sous-rubriques suivantes&nbsp;:
          </p>
          <ol className='pl-md'>
            <li>
              <Link className='link' to='/mon-compte/mes-outils'>
                Mes outils
              </Link>
              &nbsp;:
            </li>
            <ul className='dots-list'>
              <li>Vous permet d’accéder à la sous-rubrique Mes estimations, qui vous permet de&nbsp;:</li>
              <ul className='dots-list'>
                <li>
                  consulter, modifier, prévisualiser, dupliquer et archiver les estimations réalisées par la Sas
                  GALAXICO dans le cadre de votre utilisation du Site&nbsp;;
                </li>
                <li>
                  consulter et télécharger les rapports détaillés réalisés par la Sas GALAXICO dans le cadre de votre
                  utilisation du Site&nbsp;;
                </li>
                <li>démarrer une nouvelle estimation&nbsp;;</li>
                <li>
                  accéder à vos estimations archivées dans la sous-rubrique Mes estimations archivées, depuis laquelle
                  vous pouvez modifier et désarchiver vos estimations réalisées&nbsp;;
                </li>
              </ul>
              <li>Vous permet d’accéder à la sous-rubrique Mes plans de financement, qui vous permet de&nbsp;:</li>
              <ul className='dots-list'>
                <li>
                  consulter, modifier, télécharger, dupliquer et archiver les plans de financement réalisés par la Sas
                  GALAXICO dans le cadre de votre utilisation du Site&nbsp;;
                </li>
                <li>créer un nouveau plan de financement&nbsp;;</li>
                <li>
                  accéder à vos plans de financement archivés dans la sous-rubrique Mes plans de financement archivés,
                  depuis laquelle vous pouvez accéder et modifier, télécharger, dupliquer et désarchiver vos plans de
                  financement réalisées&nbsp;;
                </li>
              </ul>
              <li>Vous permet d’accéder à la sous-rubrique Mes simulations de prêt, qui vous permet de&nbsp;:</li>
              <ul className='dots-list'>
                <li>
                  consulter, télécharger, dupliquer et archiver les simulations de prêt réalisées par la Sas GALAXICO
                  dans le cadre de votre utilisation du Site&nbsp;;
                </li>
                <li>créer une nouvelle simulation de prêt&nbsp;;</li>
                <li>
                  accéder à vos simulations de prêt archivées dans la sous-rubrique Mes simulations de prêt archivées,
                  depuis laquelle vous pouvez accéder et modifier, télécharger, dupliquer et désarchiver vos plans de
                  financement réalisées&nbsp;;
                </li>
              </ul>
            </ul>
            <li>
              <Link className='link' to='/mon-compte/mes-dossiers'>
                Mes dossiers
              </Link>
              &nbsp;:
            </li>
            <ul className='dots-list'>
              <li>
                Vous permet de créer un ou plusieurs dossiers en y intégrant ou en retirant des estimations (importer
                une estimation / retirer du dossier), des plans de financement (importer un plan de financement /
                retirer du dossier) et des simulations de prêt (importer une simulation de prêt / retirer du dossier) de
                votre choix, réalisés par la Sas GALAXICO dans le cadre de votre utilisation du Site&nbsp;;
              </li>
              <li>
                Vous permet de consulter, gérer, dupliquer et archiver les dossiers que vous avez réalisés dans le cadre
                de votre utilisation du Site&nbsp;;
              </li>
              <li>Vous permet de donner un nom à chaque dossier que vous créez et d’y ajouter un descriptif&nbsp;;</li>
              <li>
                Vous permet de créer une nouvelle estimation, un nouveau plan de financement et une nouvelle simulation
                de prêt&nbsp;;
              </li>
              <li>
                Vous permet de créer un ou plusieurs livrables, de le(s) nommer, d’y importer ou retirer les
                estimations, plans de financement et simulations de prêt de votre choix, présents dans votre dossier,
                réalisés par la Sas GALAXICO dans le cadre de votre utilisation du Site&nbsp;;
              </li>
              <li>
                Vous permet de renommer, télécharger, dupliquer ou supprimer les livrables que vous avez créés&nbsp;;
              </li>
            </ul>
            <li>
              <Link className='link' to='/mon-compte/mon-profil'>
                Mon Profil
              </Link>
              &nbsp;:
            </li>
            <ul className='dots-list'>
              <li>
                Vous permet de consulter, ajouter et mettre à jour vos coordonnées (prénom, nom, raison sociale, type
                d’utilisateur, adresse e-mail, adresse postale, téléphone)&nbsp;;
              </li>
              <li>Vous permet de modifier votre mot de passe&nbsp;;</li>
              <li>Vous permet de désactiver ou supprimer votre Compte&nbsp;;</li>
              <li>
                Vous permet de modifier les visuels (couleurs des bandeaux de bas de page et logos en première page,
                tête et pieds de rapports) des rapports détaillés réalisés par la Sas GALAXICO dans le cadre de votre
                utilisation du Site&nbsp;;
              </li>
              <li>
                Vous permet d’importer ou de créer une carte de visite et de renseigner vos mentions légales, afin que
                la carte de visite et les mentions légales figurent sur vos rapports détaillés&nbsp;;
              </li>
              <li>Vous permet de vous inscrire ou vous désinscrire de la newsletter de la Sas GALAXICO&nbsp;;</li>
            </ul>
            <li>
              <Link className='link' to='/mon-compte/mes-abonnements-factures'>
                Mes abonnements et factures
              </Link>
              &nbsp;:
            </li>
            <ul className='dots-list'>
              <li>
                Vous permet de consulter les conditions de l’Offre de service en cours de validité souscrite auprès de
                la Sas GALAXICO.
              </li>
              <li>Vous permet de souscrire à des Offres additionnelles</li>
              <li>Vous permet de gérer le renouvellement de votre Offre</li>
              <li>Vous permet de gérer vos moyens de paiement</li>
              <li>
                Vous permet de consulter et télécharger les factures de vos Offres payées dans le cadre de votre
                utilisation du Site
              </li>
            </ul>
          </ol>
          <p>
            Pour permettre le bon fonctionnement de votre Compte, vous vous engagez à tenir en permanence à jour les
            informations personnelles qu’il renferme.
          </p>
          <h3 className='h2-uppercase my-md'>5 - Désactivation et Suppression</h3>
          <p>
            Vous pouvez à tout moment solliciter la désactivation ou la suppression de votre Compte sans avoir à donner
            de justification particulière, en formulant auprès de la Sas GALAXICO une demande écrite en ce sens par
            courrier postal ou e-mail, aux coordonnées indiquées en préambule.
          </p>
          <p>
            La désactivation ou la suppression de votre Compte sera effectuée par la Sas GALAXICO dans les meilleurs
            délais suivant la réception de votre demande écrite en ce sens.
          </p>
          <p>
            En cas de désactivation ou de suppression de votre Compte, toutes les sommes dues à la Sas GALAXICO par vous
            resteront dues en intégralité. Tout Offre d’abonnement en cours restera en cours jusqu’à son terme
            d’engagement, et ne sera pas renouvelée en cas de désactivation ou de suppression de votre Compte.
          </p>
          <p>
            Vous êtes informé(e) que la Sas GALAXICO peut être amenée à désactiver ou supprimer un compte qui n’aurait
            pas de commande en cours en application de la règlementation relative à la protection des données
            personnelles.
          </p>
          <TextHr className='my-xl'>Article 7. Votre responsabilité</TextHr>
          <p>
            En accédant au Site, vous vous engagez à en faire un usage paisible et vous interdisez notamment de&nbsp;:
          </p>
          <ul className='dots-list'>
            <li>
              Collecter de quelque façon que ce soit des informations sur les autres visiteurs du Site, sans leur accord
              exprès, préalable et écrit ainsi que celui de la Sas GALAXICO&nbsp;;
            </li>
            <li>
              Perturber ou tenter de perturber le fonctionnement du Site, diffuser des virus ou toutes autres
              technologies susceptibles de nuire à la Sas GALAXICO et aux autres visiteurs du Site, créer une
              saturation, inonder le serveur, tenter de sonder, de scruter ou de tester la vulnérabilité d'un système ou
              d'un réseau ou encore enfreindre les mesures de sécurité ou d'authentification sans en avoir reçu l'accord
              exprès, préalable et écrit de la Sas GALAXICO&nbsp;;
            </li>
            <li>
              Procéder de manière automatisée à l’extraction, la reproduction, l’utilisation ou la suppression de
              données issues du Site&nbsp;;
            </li>
            <li>
              Communiquer vos identifiant et mot de passe d’accès à votre Compte à des tiers, en vue de leur permettre
              d’utiliser le Site sans souscrire à l’Offre de service
            </li>
          </ul>
          <TextHr className='my-xl'>Article 8. Responsabilité de la Sas GALAXICO</TextHr>
          <h3 className='h2-uppercase my-md'>1 - Responsabilité relative au Site</h3>
          <p>
            La Sas GALAXICO s’engage à mettre en œuvre les moyens nécessaires afin d'assurer au mieux l'accès, la
            sécurité et le bon fonctionnement du Site.
          </p>
          <p>
            Ceci étant, en naviguant sur le Site, vous reconnaissez connaître et accepter les caractéristiques, risques
            et limites du réseau Internet, qui peuvent empêcher de manière temporaire l’accès au Site, en particulier
            ses performances techniques, les temps de réponse pour consulter, interroger ou transférer des données et
            les risques liés à la sécurité des communications.
          </p>
          <p>
            En dépit des soins et efforts qu'elle apporte à son Site, la Sas GALAXICO ne garantit pas que celui-ci sera
            exempt d’anomalies ou d’erreurs, que celles-ci pourront être corrigées, que le Site fonctionnera sans
            interruption ou panne, que des dysfonctionnements imputables à des logiciels de tiers surviennent, ni encore
            que le Site soit compatible avec la nature ou la configuration de votre terminal de navigation.
          </p>
          <p>
            Vous reconnaissez être seul responsable de l'utilisation que vous faites du Site et que la Sas GALAXICO
            décline toute responsabilité en cas&nbsp;:
          </p>
          <ul className='dots-list'>
            <li>
              De dommages inhérents à l'utilisation du réseau Internet, notamment en cas de suspension ou d’interruption
              de l'accès au Site en raison d’un sinistre, rupture de service, coupure d’électricité, intrusion
              extérieure, présence de virus informatiques ou de fichiers corrompus, maintenance ou mise à jour du Site,
              violation de données du Site ou accès non autorisé&nbsp;;
            </li>
            <li>
              De dommages de tous types, prévisibles ou imprévisibles (incluant la perte de données, de bénéfices, de
              chiffres d'affaires, d'activité, d’opportunité, d’économies, de profits, etc.) découlant de l’utilisation
              ou de l’impossibilité d’accéder totalement ou partiellement au Site ou de l'utilisation que vous pourriez
              en faire en violation des présentes CGU&nbsp;;
            </li>
            <li>
              De vol ou utilisation frauduleuse de vos données (notamment vos moyens de paiement et données renseignées
              dans vos estimations) de votre Compte subséquents à la communication par vos soins de vos identifiant et
              mot de passe d’accès à votre Compte à des tiers
            </li>
          </ul>
          <p>
            Vous reconnaissez et acceptez que la Sas GALAXICO se réserve la faculté, à tout moment et sans notification
            préalable, de suspendre tout ou partie de l’accès au Site, et ce notamment afin de procéder à toute
            opération de correction, de mise à jour ou de maintenance.
          </p>
          <p>
            Enfin, en complément des stipulations énoncées ci-dessus relatives à l’utilisation proprement dite du Site,
            vous êtes invité à lire attentivement les{" "}
            <Link className='link' to='/cgv'>
              Conditions Générales de Vente
            </Link>{" "}
            de la Sas GALAXICO, qui peuvent venir compléter les stipulations énoncées ci-dessus.
          </p>
          <h3 className='h2-uppercase my-md'>2 - Responsabilité relative à la documentation figurant sur le Site</h3>
          <p>
            Les informations fournies par la Sas GALAXICO dans la documentation figurant sur le Site sont fournies
            uniquement à titre informatif.
          </p>
          <p>
            Vous reconnaissez être seul responsable de l’utilisation de ces informations, de leur adéquation à vos
            besoins, de l’usage et/ou des interprétations que vous pourrez faire des documents et données consultés et
            des résultats que vous pourriez en obtenir ou en déduire.
          </p>
          <TextHr className='my-xl'>Article 9. Propriété intellectuelle</TextHr>
          <p>
            Tous les droits de propriété intellectuelle portant tant sur la structure que sur le contenu du Site, et
            notamment les éléments graphiques, textuels ou visuels, les images, sons, vidéos, photographies, logos,
            marques, signes distinctifs, outils, logiciels, documents et données, en ce compris les rapports
            d’estimation réalisés par la Sas GALAXICO sont la propriété exclusive de la Sas GALAXICO ou, le cas échéant,
            de tiers et ont, dans cette hypothèse, fait l’objet d’une autorisation expresse et préalable de reproduction
            et de représentation de la part du titulaire des droits.
          </p>
          <p>
            Ces éléments sont mis à votre disposition pour la seule utilisation du Site et dans le cadre d'une
            utilisation normale de ses fonctionnalités.
          </p>
          <p>
            Lors de l’accès et de l'utilisation du Site, vous vous engagez à respecter les droits de propriété
            intellectuelle de la Sas GALAXICO et de ses partenaires, sous peine d’engager votre responsabilité
            personnelle et de vous exposer à des poursuites civiles et/ou pénales.
          </p>
          <p>
            Il vous est interdit de reproduire, représenter, publier, transmettre, modifier, utiliser, adapter,
            traduire, diffuser, céder, exploiter ou extraire, à des fins commerciales ou non, tout ou partie des
            éléments du Site, et ce de quelque manière que ce soit, sans l'accord exprès, préalable et écrit de la Sas
            GALAXICO&nbsp;; par dérogation, vous êtes autorisé à télécharger une copie de certaines parties du Site sur
            un ordinateur personnel et/ou à procéder à une impression, exclusivement pour votre utilisation personnelle
            et non commerciale et sous réserve de n'apporter aucune modification aux éléments copiés.
          </p>
          <TextHr className='my-xl'>Article 10. Liens hypertextes</TextHr>
          <h3 className='h2-uppercase my-md'>1 - Liens hypertextes depuis le Site</h3>
          <p>
            Le Site peut contenir des liens hypertextes redirigeant vers des sites Internet exploités par des tiers.
          </p>
          <p>
            Ces liens étant fournis à simple titre d'information, la Sas GALAXICO n'exerce aucun contrôle sur les sites
            Internet vers lesquels ils renvoient et décline toute responsabilité quant à l'accès, le contenu ou
            l'utilisation de ces sites, ainsi qu'aux dommages pouvant résulter de la consultation des informations qui y
            figurent, la décision d'activer ces liens hypertextes relevant de votre responsabilité exclusive.
          </p>
          <h3 className='h2-uppercase my-md'>2 - Liens hypertextes vers le Site</h3>
          <p>
            Aucun lien hypertexte ne peut être créé vers le Site sans l'accord exprès, préalable et écrit de la Sas
            GALAXICO.
          </p>
          <p>
            Si vous entendez créer un lien hypertexte redirigeant vers le Site, et ce quel qu’en soit le support, vous
            devez préalablement prendre contact avec la Sas GALAXICO afin d'obtenir son accord exprès, préalable et
            écrit en lui adressant un e-mail à l'adresse indiquée en préambule des CGU.
          </p>
          <TextHr className='my-xl'>Article 11. Données personnelles</TextHr>
          <p>
            Dans le cadre de l’utilisation du Site, vous êtes susceptible de fournir des données personnelles vous
            concernant ou concernant des tiers faisant l’objet d’un traitement automatisé par la Sas GALAXICO, dont les
            conditions sont exposées dans la Politique de Confidentialité.
          </p>
          <p>
            Pour en savoir plus sur les conditions dans lesquelles sont traitées vos données personnelles, la Sas
            GALAXICO vous invite à consulter sa{" "}
            <Link className='link' to='/politique-confidentialite'>
              Politique de Confidentialité
            </Link>
            .
          </p>
          <TextHr className='my-xl'>Article 12. Modification du Site</TextHr>
          <p>
            La Sas GALAXICO se réserve le droit, à tout moment et sans préavis, de modifier les règles de fonctionnement
            du Site, de modifier ou supprimer tout ou partie de son contenu ainsi que d'en interrompre, temporairement
            ou définitivement, l'accessibilité, notamment afin de procéder à toute opération de correction, de
            maintenance ou de mise à jour.
          </p>
          <TextHr className='my-xl'>Article 13. Violation des CGU</TextHr>
          <p>
            En cas de constatation par la Sas GALAXICO de la violation de votre part de l'une quelconque des
            stipulations des CGU, des dispositions légales en vigueur ou des droits des tiers, et après notification
            écrite de vous mettre en conformité demeurée infructueuse pendant plus de huit jours, sauf cause grave
            justifiant l'absence de préavis ou l'application d'un préavis plus court, la Sas GALAXICO se réserve le
            droit de restreindre, suspendre ou interdire, de manière temporaire ou définitive, votre accès au Site et à
            votre Compte.
          </p>
          <TextHr className='my-xl'>Article 14. Loi applicable – Compétence juridictionnelle</TextHr>
          <p>Les CGU et tous les litiges survenant entre vous et la Sas GALAXICO sont soumis au droit français. </p>
          <p>
            Vous êtes informé qu'en cas de contestation, vous pouvez recourir à une médiation de la consommation dans
            les conditions prévues dans les Conditions Générales de Vente du Site.
          </p>
          <p>
            En cas de litige auquel aurait pu donner lieu l’application des CGU et qui ne serait pas amiablement résolu
            avec la Sas GALAXICO, les tribunaux français sont exclusivement compétents, nonobstant pluralité de
            défendeurs, appel en garantie, en référé comme au fond, en application des règles éditées par le Code de
            procédure civile français.
          </p>
          <p>
            Tout litige intervenant entre vous, dans la mesure où vous auriez agi ans le cadre de vos activités
            professionnelles, et la Sas GALAXICO auxquels les CGU pourraient donner lieu seront de la compétence
            exclusive du Tribunal de commerce de PARIS (FRANCE), tant en référé qu'au fond, et ce nonobstant pluralité
            de parties ou appel en garantie.
          </p>
          <TextHr className='my-xl'>Article 15. Dispositions diverses</TextHr>
          <h3 className='h2-uppercase my-md'>1 - Droit de cession</h3>
          <p>
            La Sas GALAXICO se réserve le droit de céder à des tiers, en tout ou partie, les droits et obligations issus
            des CGU, à condition de les céder dans les mêmes termes ou, à tout le moins, dans des termes qui ne soient
            pas moins avantageux pour vous.
          </p>
          <h3 className='h2-uppercase my-md'>2 - Modification</h3>
          <p>
            La Sas GALAXICO se réserve le droit de modifier à tout moment la teneur des CGU et vous notifiera les
            modifications ainsi effectuées par e-mail ou par tout autre moyen adéquat avant leur entrée en vigueur.
          </p>
          <p>
            L’activation de votre Compte postérieure à la notification de ces modifications sera considérée comme valant
            reconnaissance et acceptation de votre part des nouvelles CGU.
          </p>
          <h3 className='h2-uppercase my-md'>3 - Divisibilité</h3>
          <p>
            Si l'une quelconque des stipulations des CGU était déclarée nulle ou inapplicable en application d'une loi,
            d'un règlement ou à la suite d'une décision de justice devenu définitive, elle serait réputée non écrite et
            les autres stipulations resteraient en vigueur.
          </p>
          <h3 className='h2-uppercase my-md'>4 - Non-renonciation</h3>
          <p>
            Le fait que la Sas GALAXICO ne se prévale pas, à un moment ou à un autre, d’une des stipulations des CGU ne
            pourra être interprété comme valant renonciation à s’en prévaloir.
          </p>
        </div>
      </section>
    </>
  );
}

export default Cgu;
