import "./Cross.css";
import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/general/cross.svg";

function Cross({ width = "25px", rotation = "0", color = "var(--white)", className, onClick }) {
  return (
    <Icon
      style={{
        "--rotation": rotation,
        "--color": color,
        "minWidth": width,
      }}
      fill='var(--color)'
      width={width}
      height={width}
      className={"cross-icon " + className + (onClick ? " clickable-icon" : "")}
      onClick={onClick}
    />
  );
}

export default Cross;
